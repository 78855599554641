import {Component, Input} from '@angular/core';
import { NgClass, DecimalPipe } from '@angular/common';

@Component({
    selector: 'tlk-gold',
    templateUrl: './gold.component.html',
    styleUrls: ['./gold.component.scss'],
    standalone: true,
    imports: [NgClass, DecimalPipe]
})
export class GoldComponent {
  @Input()
  public Amount: number;
}
