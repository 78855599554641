import { Injectable, Inject } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '@environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // ADD cookies to API calls
        //const headers = new HttpHeaders().set('Content-Type', 'text/plain');

        // ADD url prefix based on config file
        if (request.url.startsWith('/')) {
            var apiReq = request.clone({ url: environment.apiUrl + request.url });
            return next.handle(apiReq);
        }
        //else {
        //    request = request.clone({
        //        headers,
        //        withCredentials: true
        //    });
        //}

        return next.handle(request);
    }
}
