import { HttpClient } from '@angular/common/http';
import { INation } from '@app/interfaces/nation.interface';
import { Nation } from '@app/models/nation.model';

export abstract class INationService {
  constructor(
    protected http: HttpClient
  ) { }

  abstract load(data: Nation[]);
  abstract getNationById(id: number): INation;
  abstract getNations(): INation[];
}
