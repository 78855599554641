import { ICityService } from '../services/icity.service';
import { serviceInjector } from '../services/serviceinjector';

import { IRoad } from '../interfaces/road.interface';
import { ICity } from '../interfaces/city.interface';

export class Road implements IRoad {
  private _from: ICity = null;
  private _to: ICity = null;

  public fromId: number;
  public toId: number;
  public length: number;
  public type: string;
  public path: string;

  public get from(): ICity {
    if (this._from === null)
      this._from = serviceInjector().get(ICityService).getCityById(this.fromId);

    return this._from;
  }

  public get to(): ICity {
    if (this._to === null)
      this._to = serviceInjector().get(ICityService).getCityById(this.toId);

    return this._to;
  }
}
