import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'tlk-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    standalone: true
})
export class TableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
