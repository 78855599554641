import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort',
    pure: false,
    standalone: true
})
export class SortPipe implements PipeTransform {
    transform(array: any[], field: string): any {
        if (array == null || array.length == 0)
            return array;

        if (field == null)
        {
            return array.sort((a: any, b: any) => {
                if (a < b) {
                    return -1;
                } else if (a > b) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
        else
        {
            let ascending = 1;
            if (field[0] === '-') {
                ascending = -1;
                field = field.slice(1);
            }
            return array.sort((a: any, b: any) => {
                if (a[field] < b[field]) {
                    return -ascending;
                } else if (a[field] > b[field]) {
                    return ascending;
                } else {
                    return 0;
                }
            });
        }
    }
}
