import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import {NavService} from '@app/shared/components/nav/nav.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NgClass } from '@angular/common';

@Component({
    selector: 'tlk-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    standalone: true,
    imports: [NgClass, RouterLink]
})
export class MenuItemComponent implements OnInit, OnDestroy {
  @Input()
  routerLinkUrl: string;
  locationPath: string;
  routerSubscription: Subscription;

  constructor(
    public navService: NavService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.locationPath = this.router.url;
    
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event: NavigationEnd) => {
      this.locationPath = event.url;
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  onClick(): void {
    this.navService.menuItemClicked();
  }
}
