import { Component, Input } from '@angular/core';

@Component({
    selector: 'tlk-army-icon',
    templateUrl: './army-icon.component.html',
    styleUrls: ['./army-icon.component.scss'],
    standalone: true
})
export class ArmyIconComponent {
  @Input()
  type: string;

  @Input()
  purpose: string;

  icon: string;

  constructor() { }

  ngOnChanges(): void {
    let icon = 'army';
    let type = this.type;
    let purpose = this.purpose;

    if (type == 'default') {
        let title = purpose.toLowerCase();

        if (title.indexOf("taxi") !== -1)
            icon += '-taxi';
        else if (title.indexOf("sf") !== -1)
            icon += '-sf';
        else if (title.indexOf("main") !== -1)
            icon += '-main';
    }
    else {
        if (type == 'taxi')
            icon += '-taxi';
        else if (type == 'sf')
            icon += '-sf';
        else if (type == 'main')
            icon += '-main';
    }

    icon += '-low.png';
    this.icon = icon;
  }
}
