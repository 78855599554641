import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {soldierPropertyRepresentation} from "@app/modules/city/pages/barracks/enums/soldier-property.enum";
import { NgIf, NgFor, TitleCasePipe } from '@angular/common';
import { GoldComponent } from '../../../../../../shared/components/gold/gold.component';
import { SoldierIconComponent } from '../../../../../../shared/components/soldier-icon/soldier-icon.component';

@Component({
    selector: 'tlk-barracks-soldier-card',
    templateUrl: './barracks-soldier-card.component.html',
    styleUrls: [
        '../barracks-card/barracks-card.component.scss',
        './barracks-soldier-card.component.scss'
    ],
    standalone: true,
    imports: [SoldierIconComponent, GoldComponent, NgIf, NgFor, TitleCasePipe]
})
export class BarracksSoldierCardComponent implements OnInit {
  @Output() hireSoldier: EventEmitter<any> = new EventEmitter<any>();

  @Input() soldier: any;
  @Input() MinTier: number;
  @Input() MaxTier: number;
  @Input() availableGold: number;
  @Input() availableAuthority: number;

  hiringQuantities: number[] = [1, 5, 10];

  ngOnInit(): void {
    this.soldier.rProperties = this.soldier.properties.map(property => soldierPropertyRepresentation(property));

    if (this.soldier.LeaderOnly) {
      this.hiringQuantities = [1];
    }
  }

  hire(soldier: any, quantity: number) {
    this.hireSoldier.emit({
      soldier: soldier,
      quantity: quantity,
      max: false
    });
  }

  hireMax(soldier: any) {
    this.hireSoldier.emit({
      soldier: soldier,
      quantity: 0,
      max: true
    });
  }
}
