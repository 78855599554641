import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { INation } from '@app/interfaces/nation.interface';
import { Nation } from '@app/models/nation.model';
import { StorageKey } from '@app/enums/storage-key.enum';
import { INationService } from './ination.service';
import { ServiceNotReadyError } from '@app/exceptions/service-not-ready.error';

@Injectable({
  providedIn: 'root'
})
export class NationService extends INationService {
  public nations: Nation[];

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  public load(data: Nation[]) {
    this.nations = data;
  }

  public getNationById(id: number): INation {
    if (this.nations == null)
      throw new ServiceNotReadyError("Nations service not ready yet");


    return this.nations[this.nations.findIndex(x => x.Id === id)];
  }

  public getNations(): INation[] {
    if (this.nations.length === 0)
      throw new ServiceNotReadyError("Nation service not ready yet");

    return this.nations;
  }
}
