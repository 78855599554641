import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { NgxLoadingModule } from 'ngx-loading';
import { NgIf } from '@angular/common';

@Component({
    selector: '[loader]',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [NgIf, NgxLoadingModule]
})
export class LoaderComponent {
  @Input() public loader: boolean = false;
  public promises: Promise<any>[] = [];
  @Output() public Complete: EventEmitter<void> = new EventEmitter<void>();

  constructor(element: ElementRef) {
    element.nativeElement.style.position = 'relative';
  }

  public RegisterPromise(promise: Promise<any>): Promise<any> {
    this.promises.push(promise);

    promise.finally(() => {
      let index = this.promises.indexOf(promise);

      if (index !== -1)
        this.promises.splice(index, 1);

      if (this.promises.length == 0)
        this.Complete.emit();
    });

    return promise;
  }

  public RegisterObservable(observable: Observable<any>): Promise<any> {
    return this.RegisterPromise(observable.toPromise());
  }
}
