import { Component, Input } from '@angular/core';
//import { fabo } from '@fortawesome/pro-solid-svg-icons';

import { faBowArrow } from '@fortawesome/pro-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tlk-rangedstrength',
    templateUrl: './component.html',
    styleUrls: ['./component.scss'],
    standalone: true,
    imports: [NgbTooltip, FaIconComponent]
})
export class StatsRangedStrengthComponent {
  public icon = faBowArrow;

  @Input()
  value: string;

  constructor(
  ) { }

}
