import { Component, Input } from '@angular/core';
import { IRank } from '@app/interfaces/rank.interface';
import { INation } from '@app/interfaces/nation.interface';
import { IRankService } from '@app/services/irank.service';
import { INationService } from '@app/services/ination.service';
import { RouterLink } from '@angular/router';
import { UserRankComponent } from '../user-rank/user-rank.component';
import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'tlk-player',
    templateUrl: './player.component.html',
    styleUrls: ['./player.component.scss'],
    standalone: true,
    imports: [NgIf, CountryFlagComponent, UserRankComponent, RouterLink]
})
export class PlayerComponent {
  @Input() public RankId: number;
  @Input() public Female: boolean;
  @Input() public NationId: number;
  @Input() public Name: string;

  public Rank: IRank;
  public Nation: INation;

  constructor(private RankService: IRankService, private NationService: INationService) {

  }

    ngOnInit() {
      this.Nation = this.NationService.getNationById(this.NationId);
      this.Rank = this.RankService.getRankById(this.RankId);
    }
}
