import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgeService } from '@app/services/age.service';
import { Age } from '@app/models/age.model';
import { Subscription } from 'rxjs';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'tlk-soldier-icon',
    templateUrl: './soldier-icon.component.html',
    styleUrls: ['./soldier-icon.component.scss'],
    inputs: ['soldierType', 'map', 'size'],
    standalone: true,
    imports: [NgStyle]
})
export class SoldierIconComponent implements OnInit, OnDestroy {
  soldierType: string;
  map: string = null;
  age: Age;
  ageSubscription: Subscription;
  size = 'normal';

  constructor(
    private ageService: AgeService
  ) { }

  ngOnInit(): void {
    this.ageSubscription = this.ageService.Age.subscribe(data => {
      this.age = data;
    });
  }

  ngOnDestroy() {
    this.ageSubscription.unsubscribe();
  }
}
