import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'stripStars',
    pure: true,
    standalone: true
})
export class StripStarsPipe implements PipeTransform {
    transform(value: string): any {
        return value.replace(/\*/g, '');
    }


}
