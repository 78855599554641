export enum Host {
  Live = 'lastknights.com',
  Live_KNC = 'king-and-country.com',
  Live_NG = 'ng.lastknights.com',
  Live_NG_KNC = 'ng.king-and-country.com',
  Live_WWW = 'www.lastknights.com',
  Live_WWW_KNC = 'www.king-and-country.com',

  Dev = 'dev.lastknights.com',
  Dev_KNC = 'dev.king-and-country.com',
  Dev_NG = 'ng.dev.lastknights.com',
  Dev_NG_KNC = 'ng.dev.king-and-country.com',

  Staging = 'staging.lastknights.com',
  Staging_KNC = 'staging.king-and-country.com',
  Staging_NG = 'ng.staging.lastknights.com',
  Staging_NG_KNC = 'ng.staging.king-and-country.com',

  Local = 'localhost',
  GitHub = 'the-last-knights.github.io',
}
