import { enableProdMode, ComponentRef, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { serviceInjector, ServiceInjector } from './app/services/serviceinjector';
import { AppComponent } from './app/app.component';
import { environment as environment_1 } from '@environments/environment';
import { NgxStripeModule } from 'ngx-stripe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxLoadingModule } from 'ngx-loading';
import { AppRoutingModule } from './app/app-routing.module';
import { SharedModule } from './app/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { AgeService } from './app/services/age.service';
import { TimeService } from './app/services/time.service';
import { AuthService } from './app/services/auth.service';
import { CacheService } from './app/services/cache.service';
import { ErrorInterceptor } from './app/interceptors/error.interceptor';
import { TokenInterceptor } from './app/interceptors/token.interceptor';
import { BaseUrlInterceptor } from './app/interceptors/base-url.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { HospitalService } from './app/services/hospital.service';
import { IHospitalService } from './app/services/ihospital.service';
import { SoldierService } from './app/services/soldier.service';
import { ISoldierService } from './app/services/isoldier.service';
import { RankService } from './app/services/rank.service';
import { IRankService } from './app/services/irank.service';
import { RoadService } from './app/services/road.service';
import { IRoadService } from './app/services/iroad.service';
import { CityService } from './app/services/city.service';
import { ICityService } from './app/services/icity.service';
import { NationService } from './app/services/nation.service';
import { INationService } from './app/services/ination.service';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, FormsModule, SharedModule, AppRoutingModule, NgxLoadingModule.forRoot({}), FontAwesomeModule, NgbModule, NgxStripeModule.forRoot(environment.stripeKey)),
        { provide: INationService, useClass: NationService },
        { provide: ICityService, useClass: CityService },
        { provide: IRoadService, useClass: RoadService },
        { provide: IRankService, useClass: RankService },
        { provide: ISoldierService, useClass: SoldierService },
        { provide: IHospitalService, useClass: HospitalService },
        provideCharts(withDefaultRegisterables()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseUrlInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (service: ServiceInjector) => () => service.load(),
            deps: [ServiceInjector],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (
            //cityService: ICityService,
            cacheService: CacheService, authService: AuthService, timeService: TimeService, ageService: AgeService) => () => {
                return new Promise((resolve, reject) => {
                    const promises = [
                        //cityService.loadDynamicData(),
                        cacheService.load(),
                        authService.loadUserAndPlayerDataIfLoggedIn(),
                        timeService.load(),
                        ageService.load()
                    ];
                    Promise.all(promises)
                        .then(resolve)
                        .catch(() => {
                        document.getElementById('noConnectionModal').style.display = 'flex';
                        reject();
                    });
                });
            },
            deps: [CacheService, AuthService, TimeService, AgeService],
            multi: true
        },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
})
  .catch(err => console.error(err));
