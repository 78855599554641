import { Component, Input } from '@angular/core';
import { faFistRaised } from '@fortawesome/pro-solid-svg-icons';
import { NgIf, DecimalPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tlk-strength',
    templateUrl: './component.html',
    styleUrls: ['./component.scss'],
    standalone: true,
    imports: [NgbTooltip, FaIconComponent, NgIf, DecimalPipe]
})
export class StatsStrengthComponent {
  public icon = faFistRaised;

  @Input()
  value: string;

  @Input()
  percentage: boolean = false;

  constructor(
  ) { }

}
