import { ISoldierType } from '../interfaces/soldiertype.interface';

export class SoldierType implements ISoldierType {
  Id: number;
  Name: string;
  Type: string;
  Stage: number;
  Hp: number;
  Strength: number;
  RangedStrength: number;
  RangedAccuracy: number;
  Leadership: number;
  Authority: number;
  IsPike: boolean;
  IsCavalry: boolean;
  Description: string;
  Recruitable: boolean;
  LeaderOnly: boolean;
  AdvancesFrom: number;
}
