import { HttpClient } from '@angular/common/http';
import { IRank } from '@app/interfaces/rank.interface';

export abstract class IRankService {
  constructor(
    protected http: HttpClient
  ) { }

  abstract load(data: IRank[]);
  abstract getRanks(): IRank[];
  abstract getRankById(id: number): IRank;
  abstract getRankByName(name: string): IRank;
}
