import { Component, OnInit, Input } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: '[skeleton]',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgxSkeletonLoaderModule]
})
export class SkeletonComponent implements OnInit {
  @Input() public skeleton: boolean = false;
  @Input() public skeletonTheme: any = {};
  @Input() public skeletonRows: number = 1;

  constructor() { }

  ngOnInit() {
    if (this.skeletonTheme['background-color'] === undefined) {
      this.skeletonTheme['background-color'] = '#3c2c20';
    }
    if (this.skeletonTheme['margin-bottom'] === undefined) {
      this.skeletonTheme['margin-bottom'] = '0px';
    }
  }
}
