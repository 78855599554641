import {Component, Input, OnInit} from '@angular/core';
import {PageService} from '@app/services/page.service';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'tlk-menu-group',
    templateUrl: './menu-group.component.html',
    styleUrls: ['./menu-group.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass]
})
export class MenuGroupComponent implements OnInit {
  @Input() title: string = null;
  public Expanded = true;

  constructor(
    public page: PageService
  ) {}

  ngOnInit(): void {
  }

  toggle() {
    if (this.page.isDesktopView) {
      this.Expanded = !this.Expanded;
    }
  }
}
