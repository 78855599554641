<div class="d-flex justify-content-center flex-column">

  <h2 class="Title">Cheats</h2>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-rank">Rank</label>
          <select class="w-100" [(ngModel)]="player.rank" id="cheat-rank" (ngModelChange)="cheat.run('changeRank', { rank: $event.id })">
            <option [ngValue]="rank" *ngFor="let rank of ranks">{{ rank.name.male }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-xp">XP</label>
          <input type="number" min="0" max="1500000" class="inputV2 w-100" id="cheat-xp" [(ngModel)]="player.xp" (ngModelChange)="cheat.run('changeXP', { xp: $event })">
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-hc">HC</label>
          <select class="w-100" [(ngModel)]="player.hc" id="cheat-hc" (ngModelChange)="cheat.run('changeHC', { hc: $event })">
            <option value=0>Soldier</option>
            <option value=1>WC</option>
            <option value=2>HC</option>
            <option value=3>LD</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-nation">Nation</label>
          <select class="w-100" [(ngModel)]="player.nation" id="cheat-nation" (ngModelChange)="cheat.run('changeNationality', { nationality: $event.Id })">
            <option [ngValue]="nation" *ngFor="let nation of nations">{{ nation.Name }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-city">City</label>
          <select class="w-100" [(ngModel)]="player.city" id="cheat-city" (ngModelChange)="cheat.run('changeLocation', { location: $event.id })">
            <option [ngValue]="city" *ngFor="let city of cities">{{ city.name }} | {{ city.nation.Name }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-nobility">Nobility</label>
          <select class="w-100" [(ngModel)]="user.nobility" id="cheat-nobility" (ngModelChange)="cheat.run('changeNobility', { title: $event })">
            <option value=0>Soldier</option>
            <option value=2>Duke</option>
            <option value=8 disabled>Admin</option>
            <option value=9 disabled>Superadmin</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-pvp">PvP Protection</label>
          <input type="number" min="0" max="99" class="inputV2 w-100" id="cheat-pvp" [(ngModel)]="player.pvpProtection" (ngModelChange)="cheat.run('changeNoAttack', { no_attack: $event })">
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-mp">Move Points</label>
          <input type="number" min="0" max="99" class="inputV2 w-100" id="cheat-mp" [(ngModel)]="player.movePoints" (ngModelChange)="cheat.run('changeMPs', { moves: $event })">
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-gold">Gold</label>
          <input type="number" min="0" max="20000000" class="inputV2 w-100" id="cheat-gold" [(ngModel)]="player.gold" (ngModelChange)="cheat.run('changeGold', { gold: $event })">
        </div>
      </div>
      <div class="danger col-12" style="font-size: 1.2rem;">
        Experimental
      </div>
      <div class="danger col-12 mt-n1 mb-2" style="font-size: 0.7rem;">
        Proceed with caution. The following cheats have not been tested!
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-ht">Healing Turns</label>
          <input type="number" min="0" max="5" class="inputV2 w-100" id="cheat-ht" (change)="cheat.run('changeHTs', { healing_turns: $event.target.value })">
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-idle">Idle Counter</label>
          <input type="number" min="0" max="99" class="inputV2 w-100" id="cheat-idle" (change)="cheat.run('changeIdle', { idle: $event.target.value })">
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-cos">CoS</label>
          <select value="" id="cheat-cos" class="w-100" (change)="cheat.run('setCOS', { cos: $event.target.value })">
            <option value="">Select One</option>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-hp">Health</label>
          <input type="number" min="0" max="100000" class="inputV2 w-100" id="cheat-hp" (change)="cheat.run('changeHealth', { max_hp: $event.target.value })">
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-str">Strength</label>
          <input type="number" min="0" max="100000" class="inputV2 w-100" id="cheat-str" (change)="cheat.run('changeStrength', { strength: $event.target.value })">
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-rstr">Ranged Strength</label>
          <input type="number" min="0" max="100000" class="inputV2 w-100" id="cheat-rstr" (change)="cheat.run('changeRangedStrength', { ranged: $event.target.value })">
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-racc">Ranged Accuracy</label>
          <input type="number" min="0" max="100" class="inputV2 w-100" id="cheat-racc" (change)="cheat.run('changeRangedAccuracy', { ra: $event.target.value })">
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-soldier">Soldier XP</label>
          <input type="number" min="0" max="1000000" class="inputV2 w-100" id="cheat-soldier" (change)="cheat.run('changeUnitXP', { soldierXP: $event.target.value })">
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4">
        <div class="form-group">
          <label for="cheat-hero">Hero XP</label>
          <input type="number" min="0" max="1000000" class="inputV2 w-100" id="cheat-hero" (change)="cheat.run('changeHeroXP', { heroXP: $event.target.value })">
        </div>
      </div>
      <div class="col-12 mt-2">
        <!--<div class="row">
        <div class="col-6">-->
        <div class="form-group">
          <button class="GeneralButton w-100" (click)="cheat.run('goOffline', {})">Go Offline</button>
        </div>
        <!--</div>
            <div class="col-6">
                <div class="form-group">
                    <button class="GeneralButton w-100 cheats-modal-class">Close</button>
                </div>
            </div>
        </div>-->
      </div>
    </div>
  </div>
</div>
