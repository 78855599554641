import { Component, Input, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { Nation } from '@app/models/nation.model';
import { Subscription } from 'rxjs';
import { Age } from '@app/models/age.model';
import { AgeService } from '@app/services/age.service';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';

@Component({
    selector: 'tlk-country-flag',
    templateUrl: './country-flag.component.html',
    styleUrls: ['./country-flag.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class CountryFlagComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public Nation: Nation = <Nation>{};
    @Input() public name: string;
    ageSubscription: Subscription;
    constructor(
      private ageService: AgeService
    ) { }

  ngOnInit(): void {
    //if (!this.Nation.map) {
    //  this.ageSubscription = this.ageService.Age.subscribe(data => {
    //    this.Nation.name = this.name;
    //    this.Nation.map = data.map;
    //  });
    //}
  }

    ngOnChanges() {
      if (this.name) {
        this.Nation.Name = this.name;
      }
    }

    ngOnDestroy() {
      if (this.ageSubscription) {
        this.ageSubscription.unsubscribe();
      }
    }

}
