import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AgeService } from '@app/services/age.service';
import { Age } from '@app/models/age.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tlk-hero-weapon-icon',
    templateUrl: './hero-weapon-icon.component.html',
    styleUrls: ['./hero-weapon-icon.component.scss'],
    standalone: true
})
export class HeroWeaponIconComponent implements OnInit, OnDestroy {
  @Input() public weapon: string;
  @Input() public map: string = null;

  ageSubscription: Subscription;
  age: Age;
  constructor(
    private ageService: AgeService
  ) { }

  ngOnInit(): void {
    this.ageSubscription = this.ageService.Age.subscribe(data => {
      this.age = data;
    });
  }

  ngOnDestroy() {
    this.ageSubscription.unsubscribe();
  }
}
