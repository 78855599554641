import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SnackbarService, SnackbarType } from '@app/services/snackbar.service';
import { MessageService } from '../message.service';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { PageService } from '@app/services/page.service';
import { FilterByPipe } from '../../../pipes/filter-by.pipe';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'tlk-new-message',
    templateUrl: './new-message.component.html',
    styleUrls: ['./new-message.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, FaIconComponent, FormsModule, EditorComponent, FilterByPipe]
})
export class NewMessageComponent implements OnInit {
  public faTimes = faTimes;

  @Input() hideRecipients: boolean = false;
  @Input() freezeRecipients: boolean = false;
  @Input() autofocus: boolean = true;
  @Input() selectedUsers: string[] = [];
  @Output() selectedUsersChange = new EventEmitter<string[]>();

  public users: string[];
  public userfilter: string;
  public message: string;

  constructor(private MessageService: MessageService, private snackbar: SnackbarService, public page: PageService) {

  }

  ngOnInit(): void {
    this.LoadUsers();
  }

  public LoadUsers() {
    this.MessageService.LoadRecipientList().subscribe(response => {
      this.users = response;
    });
  };

  public AddRecipient(name) {
    if (this.selectedUsers == null)
      this.selectedUsers = [];

    if (this.freezeRecipients === true)
      return;

    if (name === undefined || name === null || name.trim().length === 0)
      return;

    // Check if valid name
    let userIndex = this.users.findIndex(item => item.toLowerCase() === name.toLowerCase());

    if (userIndex === -1)
      return;

    // Check if not already in the list
    if (this.selectedUsers.findIndex(item => item.toLowerCase() === name.toLowerCase()) !== -1)
      return;

    this.selectedUsers.push(this.users[userIndex]);
    this.userfilter = '';
    this.selectedUsersChange.emit(this.selectedUsers);
    //recipientInput.focus();
  }

  public removeRecipient(index) {
    if (this.freezeRecipients === true)
      return;

    this.selectedUsers.splice(index, 1);
    this.selectedUsersChange.emit(this.selectedUsers);
  }

  public SendMessage() {
    if (this.message === null || this.message.length === 0)
      return;

    // Check if there's a name still in the checkbox, if so, see if valid and add to receivers list
    this.AddRecipient(this.userfilter);

    if (this.selectedUsers.length === 0)
      return;

    this.MessageService.SendMessage(this.selectedUsers, this.message).subscribe(response => {
      if (this.selectedUsers.length === 1)
        this.snackbar.show("Message has been sent to " + this.selectedUsers[0], SnackbarType.Success);
      else
        this.snackbar.show("Message has been sent to " + this.selectedUsers.length + " players", SnackbarType.Success);

      this.message = "";

      if (!this.freezeRecipients)
        this.selectedUsers = [];

      this.selectedUsersChange.emit(this.selectedUsers);
    });
  }
}
