<tlk-userbar [showPlayer]="page.isDesktopView" [showStatistics]="page.isDesktopView" [showClock]="!page.isDesktopView" [(ShowNav)]="navShown"></tlk-userbar>
<div class="body" (window:resize)="onResize()">
  <div class="content" style="min-height: 100%;">
    <tlk-nav *ngIf="page.isDesktopView || navShown"></tlk-nav>
    <div class="CenterBackground d-flex flex-column justify-content-between">
        <div [ngStyle]="{ 'padding': page.isDesktopView ? '0' : '0rem 0.0rem 0rem 0.7rem' }">
          <router-outlet></router-outlet>
        </div>
        <footer class="center">
          <p><small>© 2005 – 2023 <a href="/">TLK Game Development LTD</a> || <a href="/disclaimer" rel="nofollow">Disclaimer</a></small></p>
        </footer>
    </div>
  </div>
</div>
<tlk-snackbar></tlk-snackbar>

<!--<div class="d-flex" (window:resize)="onResize()">
  <tlk-nav *ngIf="page.isDesktopView || navShown"></tlk-nav>
  <div id="center" class="flex-grow-1" *ngIf="page.isDesktopView">
    <div class="CenterHeader" *ngIf="page.isDesktopView" style="margin-top: 150px;">
      <tlk-userbar [showPlayer]="true" [showStatistics]="true"></tlk-userbar>
    </div>
    <div class="CenterBackground">
      <tlk-userbar *ngIf="!page.isDesktopView"></tlk-userbar>
      <router-outlet></router-outlet>
      <tlk-snackbar></tlk-snackbar>
    </div>
    <div class="CenterFooter"></div>
  </div>
  <div id="center" class="flex-grow-1" *ngIf="!page.isDesktopView">
    <tlk-userbar [(ShowNav)]="navShown" [showClock]="true"></tlk-userbar>
    <div class="CenterBackground">
      <router-outlet></router-outlet>
      <tlk-snackbar></tlk-snackbar>
    </div>
    <div class="CenterFooter"></div>
  </div>
</div>

<footer class="center">
    <p><small>© 2005–2022 <a href="/">TLK Game Development LTD</a> || <a href="/disclaimer" rel="nofollow">Disclaimer</a></small></p>
</footer>-->
