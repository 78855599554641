import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'groupBy',
    pure: true,
    standalone: true
})
export class GroupByPipe implements PipeTransform {
    transform(array: any[], field: any): any {
        if (array == null || array.length == 0 || field == null)
            return array;

        var returnValue = array.reduce(function (rv: any, x: any)
        {
            let v = field instanceof Function ? field(x) : x[field];
            let el = rv.find((r:any) => r && r.key === v);
            if (el) {
                el.values.push(x);
            } else {
                rv.push({ key: v, values: [x] });
            } return rv;
        }, []);

        return returnValue;
    }
}
