import { Component, OnInit, OnDestroy } from '@angular/core';
import { AgeService } from '@app/services/age.service';
import { Age } from '@app/models/age.model';
import { Subscription } from 'rxjs';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'tlk-hero-icon',
    templateUrl: './hero-icon.component.html',
    styleUrls: ['./hero-icon.component.scss'],
    inputs: [
        'size',
        'name',
        'level',
        'isCavalry',
        'strength',
        'rangedStrength',
        'rangedAccuracy'
    ],
    standalone: true,
    imports: [NgStyle]
})
export class HeroIconComponent implements OnInit, OnDestroy {
  size = 'normal';
  name: string;
  level: number;
  isCavalry: boolean;
  strength: number;
  rangedStrength: number;
  rangedAccuracy: number;
  icon: string;
  age: Age;
  ageSubscription: Subscription;
  constructor(
    private ageService: AgeService
  ) { }

  ngOnInit(): void {
    this.ageSubscription = this.ageService.Age.subscribe(data => {
      this.age = data;
    });
    let iconLevel = 3;
    if (this.level < 7) {
      iconLevel = 1;
    }
    else if (this.level < 14) {
      iconLevel = 2;
    }

    if (this.isCavalry) {
      this.icon = `Hero${iconLevel}cav.png`;
    }
    else if ((this.rangedStrength * this.rangedAccuracy / 100) > this.strength) {
      this.icon = `Hero${iconLevel}rgd.png`;
    }
    else {
      this.icon = `Hero${iconLevel}.png`;
    }
  }

  ngOnDestroy() {
    this.ageSubscription.unsubscribe();
  }

}
