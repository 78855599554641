import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StandardResponse } from '@app/models/standard-response.model';
import { PlayerService } from '@app/services/player.service';
import { UnitType } from '../enums/unittype.enum';
import { ISoldierType } from '../interfaces/soldiertype.interface';
import { IHospitalService } from './ihospital.service';

@Injectable()
export class HospitalService extends IHospitalService {
  constructor(
    protected http: HttpClient,
    protected playerService: PlayerService
  ) {
    super();
  }

  claimHT(): Observable<StandardResponse> {
    return this.http.post<StandardResponse>('/json.php', {
      page: 'hospital',
      request: 'claimHT'
    });
  }

  useHT(): Observable<StandardResponse> {
    return this.http.post<StandardResponse>('/json.php', {
      page: 'hospital',
      request: 'useHT'
    });
  }

  treat(unitId: number, type: number): Observable<StandardResponse> {
    return this.http.post<StandardResponse>('/json.php', {
      page: 'hospital',
      request: 'treat',
      params: [
        type,
        unitId
      ]
    }).pipe(
      tap(response => this.playerService.setData({ gold: response.gold }))
    );
  }

  treatSelected(data: any[]): Observable<StandardResponse> {
    return this.http.post<StandardResponse>('/json.php', {
      page: 'hospital',
      request: 'treatSelected',
      params: data
    }).pipe(
      tap(response => this.playerService.setData({ gold: response.gold }))
    );
  }

  treatAll(): Observable<StandardResponse> {
    return this.http.post<StandardResponse>('/json.php', {
      page: 'hospital',
      request: 'treatAll'
    }).pipe(
      tap(response => this.playerService.setData({ gold: response.gold }))
    );
  }

  calculateTicksInjured(unittype: UnitType, damage: number) {
    switch (unittype) {
      case UnitType.Commander:
        return Math.ceil(damage / 40);
      case UnitType.Hero:
        return Math.ceil(damage / 40);
      default:
        return Math.ceil(damage / 30);
    }
  }

  calculateHealingCost(unittype: UnitType, soldiertype: ISoldierType, level: number, authority: number, damage: number): number {
    let ticksInjured = this.calculateTicksInjured(unittype, damage);

    switch (unittype) {
      case UnitType.Commander:
        return 20 * ticksInjured * authority;
      case UnitType.Hero:
        return 30 * Math.pow(ticksInjured, 2) * authority;
      default:
        let totalLevel = (soldiertype.Stage - 1) * 3 + level;

        if (totalLevel <= 3) {
          // Tier 1
          return 7 * Math.pow(2, totalLevel) * authority;
        } else {
          // Tier 2 and 3
          let cost = 30 * Math.pow(ticksInjured, 2) * authority;

          if (totalLevel == 10) {
            // Elite, special case as authority no longer increases
            cost *= 1.1;
          }
          else if(totalLevel == 11) {
            // Legendary, special case as authority no longer increases
            cost *= 1.2;
          }

          return Math.round(cost);
        }
    }
  }
}
