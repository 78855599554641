import { Component, Input } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tlk-pike',
    templateUrl: './component.html',
    styleUrls: ['./component.scss'],
    standalone: true,
    imports: [NgbTooltip, DecimalPipe]
})
export class StatsPikeComponent {

  @Input()
  value: string;

  constructor(
  ) { }

}
