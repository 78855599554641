import { IRank } from '../interfaces/rank.interface';

export class Rank implements IRank {
    id: number;
    name: {
      male: string,
      female: string
    };
    authority: number;
    experience: number;
    value: number;
    MinTier: number;
    MaxTier: number;
}
