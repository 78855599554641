import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addSign',
    pure: true,
    standalone: true
})
export class AddSignPipe implements PipeTransform {
    transform(value: number): any {
        if (value > 0)
            return "+" + value.toString();

        return value.toString()
    }


}
