import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from '@app/services/auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Token } from '@app/models/token.model';
import { SnackbarService, SnackbarType } from '../services/snackbar.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private refreshTokenRequestInProgress = false;
  private refreshTokenSubject: BehaviorSubject<Token> = new BehaviorSubject<Token>(null);
  
  constructor(
    private auth: AuthService,
    private router: Router,
    private snackbar: SnackbarService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.token;

    return next.handle(this.injectToken(request, token)).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 401) {
          if (error.error.indexOf('JWT Authentication error:') !== -1 && error.error.indexOf('Expired token') !== -1 && request.body.request !== 'refreshTokens') {
            if (this.refreshTokenRequestInProgress) {
              // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
              // which means the new token is ready and we can retry the request again
              return this.refreshTokenSubject.pipe(
                filter(response => response !== null),
                take(1),
                switchMap(token => next.handle(this.injectToken(request, token)))
              );
            }
            else {
              this.refreshTokenRequestInProgress = true;
              // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
              this.refreshTokenSubject.next(null);

              return this.auth.refreshToken().pipe(
                switchMap((token: Token) => {
                  // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  this.refreshTokenRequestInProgress = false;
                  this.refreshTokenSubject.next(token);

                  return next.handle(this.injectToken(request, token));
                }),
                catchError(error => {
                  this.refreshTokenRequestInProgress = false;
                  // since the refresh failed, logout
                  this.kickToLogoutPage();

                  return throwError(error);
                })
              );
            }
          }

          this.snackbar.show(error.error, SnackbarType.Error);
          this.kickToLogoutPage();
        }

        return throwError(error);
      })
    );
  }

  injectToken(request: HttpRequest<any>, token: Token) {
    // check if token is null, if it is then the user is not logged in
    // or if request is for a logout or refresh
    if (token === null || (request.body && (request.body.request === 'refreshTokens' || request.body.request === 'deleteAuthToken'))) {
      return request;
    }
    // else inject access token
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token.access}`
      }
    });
  }

  kickToLogoutPage() {
    if (this.auth.token && this.auth.token.refresh) {
      this.auth.logout().subscribe(() => {
        this.router.navigateByUrl('/');
      });
    }
    else {
      this.auth.deleteTokens();
      this.router.navigateByUrl('/');
    }    
  }
}
