import { Injector, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceInjector {
  constructor(
    private injector: Injector
  ) { }

  public load(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      serviceInjector(this.injector);
      resolve();
    });
  }
}

let serviceInjectorRef: Injector;

export const serviceInjector = (injector?: Injector): Injector => {
  if (injector) {
    serviceInjectorRef = injector;
  }

  return serviceInjectorRef;
};
