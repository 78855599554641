import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivityTrackerService } from './services/activitytracker.service';
import { ActiveAgeGuard } from './shared/guards/active-age.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'city',
    loadChildren: () => import('./modules/city/city.module').then(m => m.CityModule),
    canActivate: [ActiveAgeGuard]
  },
  {
    path: 'nation',
    loadChildren: () => import('./modules/nation/nation.module').then(m => m.NationModule)
  },
  {
    path: 'telegram',
    loadChildren: () => import('./modules/telegram/telegram.module').then(m => m.TelegramModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./modules/map/map.module').then(m => m.MapModule)
  },
  {
    path: 'battle',
    loadChildren: () => import('./modules/battle/battle.module').then(m => m.BattleModule),
    canActivate: [ActiveAgeGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'misc',
    loadChildren: () => import('./modules/misc/misc.module').then(m => m.MiscModule)
  },
  {
    path: 'neptune',
    loadChildren: () => import('./modules/events/neptune/neptune.module').then(m => m.NeptuneModule)
  },
  {
    path: 'pandemic',
    loadChildren: () => import('./modules/events/pandemic/pandemic.module').then(m => m.PandemicModule)
  },
  {
    path: 'navy',
    loadChildren: () => import('./modules/events/navy/navy.module').then(m => m.NavyModule)
  },
  {
    path: 'forum',
    loadChildren: () => import('./modules/forum/forum.module').then(m => m.ForumModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private ActivityTrackerService: ActivityTrackerService
  ) {}
}
