import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheatsComponent } from '../../components/cheats/cheats.component';

@Component({
    templateUrl: './dev-cheats.popup.html',
    styleUrls: ['./dev-cheats.popup.scss'],
    standalone: true,
    imports: [CheatsComponent]
})
export class DevCheatsPopup implements OnInit {
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {

  }

}
