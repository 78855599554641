import { Component, Input, OnInit } from '@angular/core';
import { SnackbarService, SnackbarType } from '@app/services/snackbar.service';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'tlk-info-button',
    templateUrl: './info-button.component.html',
    styleUrls: ['./info-button.component.scss'],
    standalone: true,
    imports: [FaIconComponent]
})
export class InfoButtonComponent implements OnInit {
  faInfoCircle = faInfoCircle;

  @Input()
  text: string;

  @Input()
  info: string;

  constructor(private snackbar: SnackbarService) { }

  ngOnInit(): void {
  }

  showInfo() {
    this.snackbar.show(this.info, SnackbarType.Info);
  }

}
