import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
    name: 'LuxonToLocal',
    pure: true,
    standalone: true
})
export class LuxonToLocalPipe implements PipeTransform {
  transform(input: DateTime, outputformat: string = 'yyyy-MM-dd H:mm:ss'): any {
    if (input == null)
      return null;

    return input.toLocal().toFormat(outputformat);
  }
}
