import { Component, Input } from '@angular/core';
import { faCrosshairs } from '@fortawesome/pro-solid-svg-icons';
import { DecimalPipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tlk-rangedaccuracy',
    templateUrl: './component.html',
    styleUrls: ['./component.scss'],
    standalone: true,
    imports: [NgbTooltip, FaIconComponent, DecimalPipe]
})
export class StatsRangedAccuracyComponent {
  public icon = faCrosshairs;

  @Input()
  value: string;

  constructor(
  ) { }

}
