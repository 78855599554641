import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  private menuItemClickedSource = new Subject<void>();
  menuItemClicked$ = this.menuItemClickedSource.asObservable();

  menuItemClicked() {
    this.menuItemClickedSource.next();
  }
}
