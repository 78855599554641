<div *ngIf="!hideRecipients">
  <div>
    Recipients:
  </div>
  <div style="display:flex; align-items:center;">
    <div class="Recipient"
         *ngFor="let recipient of selectedUsers; let i = index"
         (click)="removeRecipient(i)">
      {{recipient}} <fa-icon [icon]="faTimes"></fa-icon>
    </div>

    <input id="recipientInput"
           *ngIf="!freezeRecipients"
           type="text"
           [(ngModel)]="userfilter"
           placeholder="Add Recipient"
           class="inputV2"
           style="margin: 0px 5px;">

    <ul class="RecipientFilterList"
        *ngIf="userfilter != null && userfilter.length >= 2">
      <li *ngFor="let name of users | filterBy:userfilter"
          (click)="AddRecipient(name)">{{name}}</li>
    </ul>
  </div>
</div>
<div style="margin: 10px 0px;">
  <editor [(ngModel)]="message" [init]="{
      base_url: page.baseUrl + 'tinymce',
      suffix: '.min',
      menubar: false,
      auto_focus: autofocus,
      plugins: [
         'lists paste'
      ],
      toolbar:
         'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent'
   }"></editor>
</div>
<div>
  <input type="button" class="RedButton" value="Send" (click)="SendMessage()" />
</div>
