import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'tlk-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
    standalone: true
})
export class TabsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
