import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MessageService } from '../message.service';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { AgeService } from '@app/services/age.service';
import { Subscription } from 'rxjs';
import { Age } from '@app/models/age.model';
import { ReplaceWithLocalPipe } from '../../../pipes/replace-with-local.pipe';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { PlayerComponent } from '../../player/player.component';
import { CountryFlagComponent } from '../../country-flag/country-flag.component';
import { UserRankComponent } from '../../user-rank/user-rank.component';
import { NgIf, NgFor } from '@angular/common';
import { TimeComponent } from '../../time/time.component';

@Component({
    selector: 'tlk-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
    standalone: true,
    imports: [TimeComponent, NgIf, UserRankComponent, RouterLink, CountryFlagComponent, NgFor, PlayerComponent, FaIconComponent, ReplaceWithLocalPipe]
})
export class MessageComponent {
  public faCheck = faCheck;

  @Input() message: any;
  @Input() hideReply: boolean;
  @Output() onReply: EventEmitter<any> = new EventEmitter<any>();

  ageSubscription: Subscription;
  age: Age;

  constructor(private Router: Router, private MessageService: MessageService, private ageService: AgeService) { }

  ngOnInit() {
    this.ageSubscription = this.ageService.Age.subscribe(data => this.age = data);
  }

  ngOnDestroy() {
    this.ageSubscription.unsubscribe();
  }

  public ShowReceiverDetails: boolean = false;

  public ToggleReceiverDetails()
  {
    this.ShowReceiverDetails = !this.ShowReceiverDetails;

    //Get Details
    if (this.message.ReceiverDetails == null || this.message.ReceiverDetails.length == 0) {
      this.MessageService.getReceiverDetails(this.message.Id).subscribe(response => {
        this.message.ReceiverDetails = response;
      });
    }
  }

  public Reply()
  {
    if (this.onReply.observers.length > 0)
      this.onReply.emit(this.message);
    else
      this.Router.navigate(['/user/messages'], { queryParams: { receivers: [this.message.Sender] } });
  }
}
