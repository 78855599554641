export class User {
  id: number;
  name: string;
  nobility: number;
  unreadMessages: number;
  unreadBattleCount: number;
  timezone: string;
  playSounds: boolean;
  adminPermissionsCount: number;
  email: string;
  lastLogin: string;
  profileNote: string;
  showDidYouKnows: boolean;
  noDelete: boolean;
  safeMode: boolean;
}
