<div class="SubmenuTitle" (click)="toggle()">
  <div class="GoldenArrow" *ngIf="page.isDesktopView">
    <img *ngIf="!Expanded"
         src="/assets/images/ui/GoldArrow_Up.png">
    <img *ngIf="Expanded"
         src="/assets/images/ui/GoldArrow_Down.png">
  </div>
  <h3>{{title}}</h3>
</div>
<ul *ngIf="!page.isDesktopView || Expanded" [ngClass]="{ 'flex-menu': !page.isDesktopView }">
  <ng-content></ng-content>
</ul>
