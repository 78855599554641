import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration',
    pure: true,
    standalone: true
})
export class DurationPipe implements PipeTransform {
    transform(totalSeconds: number): any {
        var minutes = Math.floor(totalSeconds / 60);
        var seconds = totalSeconds - (minutes * 60);

        //// round seconds
        //seconds = Math.round(seconds * 100) / 100

        var result = (minutes < 10 ? "0" + minutes : minutes);
        result += ":" + (seconds < 10 ? "0" + seconds : seconds);

        return result;
    }


}
