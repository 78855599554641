import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'distinct',
    standalone: true,
})
export class DistinctPipe implements PipeTransform {
  transform(value: any[], property: string = null): any {
    if (property != null)
      value = value.reduce((acc, current) => {
        let values = current[property];
        return acc.concat(values);
      }, []);

    let result = value.reduce((acc: any[], current: any) => {
      if (current !== undefined && acc.indexOf(current) == -1)
        acc.push(current);

      return acc;
    }, []);

    return result;
  }
}
