import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
    name: 'replaceWithLocal',
    pure: true,
    standalone: true
})
export class ReplaceWithLocalPipe implements PipeTransform {
  private ShortTimeRegex = RegExp('\\b(\\d+):(\\d+)\\b', "g");

  transform(input: string): any {
    if (input == null)
      return input;

    return input.replace(this.ShortTimeRegex, function (match, contents, offset, input_string) {
      return DateTime.fromFormat(match, "H:mm", { zone: 'utc' }).toLocal().toFormat("HH:mm");
    });
  }
}
