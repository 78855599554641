// Import the core angular services.
import { Component, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { ContentChild } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { TemplateRef } from "@angular/core";
import { SortPipe } from "../../pipes/sort.pipe";
import { GroupByPipe } from "../../pipes/group-by.pipe";
import { FilterByPipe } from "../../pipes/filter-by.pipe";
import { FormsModule } from "@angular/forms";
import { NgIf, NgTemplateOutlet, NgFor } from "@angular/common";

@Component({
    selector: "tlk-html-dropdown",
    inputs: ["items", "value", "placeholder", "GroupBy"],
    outputs: ["valueChange"],
    // Query for the template being provided by the calling context.
    queries: {
        itemTemplate: new ContentChild(TemplateRef)
    },
    host: {
        "[class.is-open]": "isShowingItems"
    },
    templateUrl: './html-dropdown.component.html',
    styleUrls: ['./html-dropdown.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgTemplateOutlet,
        FormsModule,
        NgFor,
        FilterByPipe,
        GroupByPipe,
        SortPipe,
    ],
})
export class HtmlDropdownComponent {
    @ViewChild('txtFilter') txtFilter: ElementRef;
    public itemTemplate = new ContentChild( TemplateRef );
    public Filter: string = null;
    public GroupBy: string = null;

    public isShowingItems: boolean;
    public items: any[];
    public placeholder: string;
    public value: any;

    public valueChange: EventEmitter<any>;

    constructor(private ChangeDetector: ChangeDetectorRef) {
        this.isShowingItems = false;
        this.valueChange = new EventEmitter();
    }

    public hideItems() : void {
        this.isShowingItems = false;
    }

    public selectItem(item: any) : void {
        this.hideItems();
        this.valueChange.emit(item);
    }

    public showItems() : void {
        this.isShowingItems = true;
        this.ChangeDetector.detectChanges();
        this.txtFilter.nativeElement.focus();
    }

    public toggleItems() : void {
        this.isShowingItems
            ? this.hideItems()
            : this.showItems()
        ;
    }
}
