import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortByUnitLevel',
    pure: true,
    standalone: true
})
export class SortByUnitLevelPipe implements PipeTransform {
    getPreference(level: string): number {
        if (level === 'Apprentice') {
            return 0;
        }
        else if (level === 'Standard') {
            return 1;
        }
        else if (level === 'Veteran') {
            return 2;
        }
        else if (level === 'Master') {
            return 3;
        }
        else if (level === 'Elite') {
            return 4;
        }
        else {
            return 5;
        }
    }
    
    transform(value: any[]): any[] {
        return value.sort((a, b) => this.getPreference(b.key) - this.getPreference(a.key));
    }
}
