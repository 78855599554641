import { Component, Input, OnInit } from '@angular/core';
import { TimeService } from '@app/services/time.service';
import { NgIf } from '@angular/common';
import { TimeComponent } from '../time/time.component';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'tlk-game-clock',
    templateUrl: './game-clock.component.html',
    styleUrls: ['./game-clock.component.scss'],
    standalone: true,
    imports: [RouterLink, TimeComponent, NgIf]
})
export class GameclockComponent implements OnInit {
  constructor(public TimeService: TimeService) { }

  @Input() showDelta: boolean = true;

  ngOnInit() {

  }
}
