import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MessageService {

  constructor(
    private http: HttpClient
  ) { }

  public getReceiverDetails(messageId: number): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'messages',
      request: 'getReceiverDetails',
      params: {
        messageId: messageId
      }
    });
  }

  public LoadRecipientList(): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'messages',
      request: 'getAllNames'
    });
  }

  public getInbox(page: number, pagesize: number): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'messages',
      request: 'Inbox',
      params: {
        page: page,
        pageSize: pagesize
      }
    });
  }

  public getOutbox(page: number, pagesize: number): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'messages',
      request: 'Outbox',
      params: {
        page: page,
        pageSize: pagesize
      }
    });
  }

  public getConversation(page: number, pagesize: number, userId: number): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'messages',
      request: 'getConversation',
      params: {
        page: page,
        pageSize: pagesize,
        player2Id: userId
      }
    });
  }

  public SendMessage(receivers: string[], message: string): Observable<any> {
    return this.http.post('/json.php', {
      page: 'messages',
      request: 'SendMessage',
      params: {
        message: message,
        receivers: receivers
      }
    });
  }
}
