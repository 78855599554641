import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class KeyboardShortcutService {
  shortcuts = {
    'a': '/city/armory',
    'b': '/city/border',
    'c': '/city',
    'e': '/user/encampment',
    'h': '/city/hospital',
    'k': '/city/barracks',
    'l': '/battle/your-battles',
    'm': '/map',
    'o': '/user/buddies',
    'p': '/user/settings',
    'q': '/city/headquarters',
    'r': '/user/rankings',
    's': '/user/messages',
    't': '/city/tournament',
    'v': '/city/castle',
    'w': '/misc/wars',
    'x': '/battle/training',
    'y': '/nation/players'
  }

  constructor(
    private router: Router
  ) { }

  listen() {
    window.addEventListener('keydown', (event: any) => {
      // check if currently not focused on some input element
      if (event.target.nodeName !== 'TEXTAREA' && event.target.nodeName !== 'INPUT') {
        if (event.key && event.key.toLowerCase() in this.shortcuts && !event.ctrlKey) {
          this.router.navigateByUrl(this.shortcuts[event.key.toLowerCase()]);
        }
      }
    });
  }
}
