<div id="header" class="d-flex flex-row align-items-center">
  <div id="soldierIcon"><tlk-soldier-icon [soldierType]="soldier.Name"></tlk-soldier-icon></div>
  <div class="d-flex d-md-none flex-column">
    <div id="soldierName">{{ soldier.Name }}</div>
    <div id="soldierHiringCost"><tlk-gold [Amount]="soldier.HiringCost"></tlk-gold></div>
  </div>
  <div class="d-none d-md-flex flex-row align-items-center w-100">
    <div id="soldierName">{{ soldier.Name }}</div>
    <div id="soldierHiringCost" class="ms-auto"><tlk-gold [Amount]="soldier.HiringCost" style="font-size: 1.2rem" class="MedievalSharp"></tlk-gold></div>
  </div>
</div>
<div id="main" class="w-100">
  <div id="stats" class="w-100">
    <div class="w-100">
      <table class="w-100 mx-auto">
        <tr>
          <td class="key">Class</td>
          <td class="value">{{ soldier.Role | titlecase }}</td>
          <td class="key">Melee strength</td>
          <td class="value">{{ soldier.Strength > 0 ? soldier.Strength : '-' }}</td>
        </tr>
        <tr>
          <td class="key">Health</td>
          <td class="value">{{ soldier.MaxHealth }}</td>
          <td class="key">Ranged strength</td>
          <td class="value">{{ soldier.RangedStrength > 0 ? soldier.RangedStrength : '-' }}</td>
        </tr>
        <tr>
          <td class="key">Authority</td>
          <td class="value">{{ soldier.Authority }}</td>
          <td class="key">Ranged accuracy</td>
          <td class="value">{{ soldier.RangedStrength > 0 && soldier.RAcc > 0 ? soldier.RAcc + '%' : '-' }}</td>
        </tr>
      </table>
    </div>
  </div>
  <!-- <div id="properties" class="d-none d-md-inline-block">
    <ul *ngIf="soldier.properties && soldier.properties.length > 0">
      <li *ngFor="let property of soldier.rProperties" class="text-justify"><b>{{ property.name }}</b> - {{ property.description }}</li>
    </ul>
  </div> -->
</div>
<div id="footer">
  <div id="actions">
    <div *ngIf="soldier.Tier >= MinTier && soldier.Tier <= MaxTier; else tierUnavailableAtRank" class="d-flex justify-content-around justify-content-md-end flex-wrap align-items-center" style="gap: 0.6rem">
      <div class="me-auto Trajan d-none d-md-block">Hire</div>
      <button
        class="GeneralButton LightButton"
        *ngFor="let quantity of hiringQuantities"
        [disabled]="soldier.HiringCost * quantity > availableGold || soldier.Authority * quantity > availableAuthority ? 'disabled' : null"
        (click)="hire(soldier, quantity)">{{ quantity }}</button>
      <button
        class="RedButton"
        [disabled]="soldier.HiringCost > availableGold || soldier.Authority > availableAuthority ? 'disabled' : null"
        (click)="hireMax(soldier)">Max</button>
    </div>
    <ng-template #tierUnavailableAtRank>
      <span class="danger" style="font-style: italic;">Tier {{ soldier.Tier }} unavailable at your rank</span>
    </ng-template>
  </div>
</div>
