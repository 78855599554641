import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { CacheService } from '@app/services/cache.service';
import { NgClass } from '@angular/common';

@Component({
    selector: 'tlk-svg',
    templateUrl: './svg.component.html',
    styleUrls: ['./svg.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class SvgComponent implements OnInit {
  @Input()
  url: string;
  @Input()
  fill: string = '';

  svg: SafeHtml;

  constructor(
    private cacheService: CacheService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    var url = location.origin + this.url;
    //console.log(url);
    this.cacheService.loadBlob(url).then(svg => this.svg = this.sanitizer.bypassSecurityTrustHtml(svg));
  }

}
