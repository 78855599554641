import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'limit',
    pure: true,
    standalone: true
})
export class LimitPipe implements PipeTransform {
    transform(value: any[], count: number): any {
        return value.slice(0, count);
    }
}
