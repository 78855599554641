import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StandardResponse } from '@app/models/standard-response.model';
import { PlayerService } from '@app/services/player.service';
import { UnitType } from '../enums/unittype.enum';
import { ISoldierType } from '../interfaces/soldiertype.interface';

export abstract class IHospitalService {
  abstract claimHT(): Observable<StandardResponse>;
  abstract useHT(): Observable<StandardResponse>;
  abstract treat(unitId: number, type: number): Observable<StandardResponse>;
  abstract treatSelected(data: any[]): Observable<StandardResponse>;
  abstract treatAll(): Observable<StandardResponse>;
  abstract calculateTicksInjured(unittype: UnitType, damage: number);
  abstract calculateHealingCost(unittype: UnitType, soldiertype: ISoldierType, level: number, authority: number, damage: number): number;
}
