import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterBy',
    pure: false,
    standalone: true
})
export class FilterByPipe implements PipeTransform {
  transform(array: any[], filter: string): any {
    if (array == null || array.length == 0 || filter == null)
      return array;

    var searchTerms = filter.toLowerCase().split(" ");

    var returnlist = array.filter(element => {
      if (typeof element === 'string') {
        let stringValue: string = element;
        for (var term of searchTerms) {
          if (stringValue.toLowerCase().indexOf(term) == -1)
            return false;
        }

        return true;
      }
      else {
        var values = Object.keys(element).map(key => element[key]);

        for (var term of searchTerms) {
          if (values.find(o => String(o).toLowerCase().indexOf(term) != -1) == null) {
            return false;
          }
        }

        return true;
      }
    });

    return returnlist;
  }
}
