import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IRank } from '@app/interfaces/rank.interface';
import { Rank } from '@app/models/rank.model';
import { StorageKey } from '@app/enums/storage-key.enum';
import { IRankService } from './irank.service';
import { ServiceNotReadyError } from '@app/exceptions/service-not-ready.error';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RankService extends IRankService {
  private ranks: Rank[];

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  public load(data: IRank[]) {
    this.ranks = data.map(rank => Object.assign(new Rank(), rank as Rank));
  }

  public getRanks(): IRank[] {
    if (this.ranks == null)
      throw new ServiceNotReadyError("Ranks service not ready yet");

    return this.ranks;
  }

  public getRankById(id: number): IRank {
    if (this.ranks == null)
      throw new ServiceNotReadyError("Ranks service not ready yet");

      return this.ranks[this.ranks.findIndex(x => x.id === id)];
  }

  public getRankByName(name: string): IRank {
    if (this.ranks == null)
      throw new ServiceNotReadyError("Ranks service not ready yet");

    return this.ranks[this.ranks.findIndex(x => x.name.male === name || x.name.female === name)];
  }
}
