import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'tlk-table-row',
    templateUrl: './table-row.component.html',
    styleUrls: ['./table-row.component.scss'],
    standalone: true
})
export class TableRowComponent implements OnInit {
  @Input() headerRow = false;

  constructor() { }

  ngOnInit(): void {
  }

}
