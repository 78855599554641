import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  public Encrypt(key, message) {
    let messageCharacters = message.split("");
    let keyCharacters = key.split("");

    let enc = "";

    for (let i = 0; i < messageCharacters.length; i++) {
      // create block
      let a = messageCharacters[i].charCodeAt(0);
      let k = keyCharacters[i % keyCharacters.length].charCodeAt(0);

      // bitwise XOR
      let b = a ^ k;
      enc = enc + String.fromCharCode(b);
    }

    return this.EncodeToHex(enc);
  }

  public EncodeToHex(s) {
    var r = "";
    var hexes = new Array("0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e", "f");
    for (var i = 0; i < s.length; i++) { r += hexes[s.charCodeAt(i) >> 4] + hexes[s.charCodeAt(i) & 0xf]; }
    return r;
  }
}
