import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'tlk-table-cell',
    templateUrl: './table-cell.component.html',
    styleUrls: ['./table-cell.component.scss'],
    standalone: true
})
export class TableCellComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
