<div class="d-flex justify-content-center h-100 align-items-center">
    <a [routerLink]="['/user/settings/timezone']"
       title="Set your timezone"
       class="clock">
       <div id="game-clock" title="Server time: {{TimeService.Time}}">
         <div id="my-time">
           <t [L]="TimeService.Time" F="HH:mm"></t>
           <span *ngIf="showDelta" class="delta">{{TimeService.TimeDifference}}</span>
         </div>
           <div class="next-tick">
             Next tick {{TimeService.NextTick}}
           </div>
       </div>
     </a>
 </div>
