import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
    name: 'toLocal',
    pure: true,
    standalone: true
})
export class ToLocalPipe implements PipeTransform {
  transform(input: string, inputformat: string = 'yyyy-MM-dd H:mm:ss', outputformat: string = 'd MMM H:mm'): any {
    if (input == null)
      return null;

    return DateTime.fromFormat(input, inputformat, { zone: 'utc' }).toLocal().toFormat(outputformat);
  }
}
