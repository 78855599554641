import { Injectable } from '@angular/core';
import { Host } from '@app/enums/host.enum';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private _isDesktopView: boolean;
  private _isHighResolutionView: boolean;

  constructor() {
    this.checkWidth();
  }

  public get isDesktopView(): boolean {
    return this._isDesktopView;
  }

  public get isHighResolutionView(): boolean {
    return this._isHighResolutionView;
  }

  public checkWidth() {
    this._isDesktopView = window.innerWidth >= 768;
    this._isHighResolutionView = window.innerWidth >= 992;
  }

  public get isDev(): boolean {
    return [Host.Dev, Host.Dev_KNC, Host.Dev_NG, Host.Dev_NG_KNC, Host.Local, Host.GitHub]
      .includes(window.location.hostname as Host);
  }

  public get isStaging(): boolean {
    return [Host.Staging, Host.Staging_KNC, Host.Staging_NG, Host.Staging_NG_KNC]
      .includes(window.location.hostname as Host);
  }

  public get isDevelopment(): boolean {
    return this.isDev || this.isStaging;
  }

  public get baseUrl(): string {
    return window.location.hostname === Host.GitHub ? '/AngularClient/' : '/';
  }
}
