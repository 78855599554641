import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IPlayer } from '@app/interfaces/player.interface';
import { Player } from '@app/models/player.model';
import { share, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { StandardResponse } from '@app/models/standard-response.model';
import { ICityService } from './icity.service';
import { INationService } from './ination.service';
import { PlayerRanking } from '../models/playerranking.model';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  private data$: BehaviorSubject<Player> = new BehaviorSubject<Player>(null);
  private ranking$: BehaviorSubject<PlayerRanking> = new BehaviorSubject<PlayerRanking>(null);

  constructor(
    private http: HttpClient,
    private CityService: ICityService,
    private NationService: INationService
  ) { }

  public getData(): Observable<Player> {
    return this.data$.asObservable().pipe(
      share()
    );
  }

  public setData(data: Partial<IPlayer>): void {
    let player = this.data$.getValue();

    if (player === undefined || player == null)
      player = Object.assign(new Player(), data);
    else
      Object.assign(this.data$.getValue(), data);

    return this.data$.next(player);
  }

  public getRanking(): Observable<PlayerRanking> {
    return this.ranking$.asObservable().pipe(
      share()
    );
  }

  public setRanking(data: Partial<PlayerRanking>): void {
    let ranking = this.ranking$.getValue();

    if (ranking === undefined || ranking == null)
      ranking = Object.assign(new PlayerRanking(), data);
    else
      Object.assign(this.ranking$.getValue(), data);

    return this.ranking$.next(ranking);
  }

  public changeName(name: string): Observable<void> {
    return this.http.post<void>('/json.php', {
      page: 'user',
      request: 'ChangeUsername',
      params: {
        username: name
      }
    }).pipe(
      tap(() => this.setData({ name }))
    );
  }

  public travel(cityId: number): Observable<StandardResponse> {
    return this.http.post<StandardResponse>('/json.php', {
      page: 'citycenter',
      request: 'moveTo',
      params: [cityId, this.data$.getValue().city.id]
    }).pipe(
      tap(response => {
        this.setData({
          movePoints: response.moves,
          city: this.CityService.getCityById(response.cityId)
        });
      })
    );
  }

  public joinNation(nationId: number): Observable<StandardResponse> {
    return this.http.post<StandardResponse>('/json.php', {
      page: 'joinNation',
      request: 'joinNation',
      params: [nationId]
    }).pipe(
      tap(response => {
        this.setData({
          city: this.CityService.getCityById(response.cityId),
          nation: this.NationService.getNationById(response.nationId)
        });
      })
    );
  }

  public leaveArmy(): Observable<StandardResponse> {
    return this.http.post<StandardResponse>('/json.php', {
      page: 'citycenter',
      request: 'leaveArmy'
    }).pipe(
      tap(() => this.setData({ commanderId: 0, commanderName: null }))
    );
  }

  public resurrect(): Observable<StandardResponse> {
    return this.http.post<StandardResponse>('/json.php', {
      page: 'citycenter',
      request: 'resurrect'
    }).pipe(
      tap(() => this.setData({ dead: false }))
    );
  }

  public getPlayerRanking(): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'polling',
      request: 'PlayerStats'
    });
  }

}
