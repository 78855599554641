import { HttpClient } from '@angular/common/http';
import { IRoad } from '@app/interfaces/road.interface';
import { Observable } from 'rxjs';

export abstract class IRoadService {
  constructor(
    protected http: HttpClient
  ) { }

  abstract load(data: IRoad[]);
  abstract getCityRoads(id: number): IRoad[];
  abstract getRoads(): IRoad[];
  abstract getSeaRoutes(): IRoad[];
  abstract fetchDynamicData(): Observable<IRoad[]>;
}
