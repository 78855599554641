<div>
    <h2 class="Title">Battle Result</h2>
    <div class="table-responsive">
        <table width="100%">
            <tr><td colspan="11" class="text-center">{{ result.time | toLocal }}</td></tr>
            <tr>
                <td colspan="5" class="text-center">
                    <tlk-country-flag [Nation]="{ Name: result.nname1, Map: age.map }"></tlk-country-flag>
                    {{ result.player1_name }} 
                    {{ result.terrain_bonus1 }}%
                </td>
                <td class="text-center">
                    {{ result.winner == 1 ? 'defeats' : result.winner == 2 ? 'loses to' : 'ties with' }}
                </td>
                <td colspan="5" class="text-center">
                    <tlk-country-flag [Nation]="{ Name: result.nname2, Map: age.map }" *ngIf="result.nname2"></tlk-country-flag>
                    {{ result.player2_name }} 
                    {{ result.terrain_bonus2 }}%
                </td>
            </tr>
            <tr><td colspan="11"><hr class="default-hr mb-2"></td></tr>
            <tr>
                <td width="25%"><b>Soldiers</b></td>
                <td width="5%"><b>Tot</b></td>
                <td width="5%"><b>Inj</b></td>
                <td width="5%"><b>Dead</b></td>
                <td width="5%"><b>Capt</b></td>
                <td width="10%"></td>
                <td width="25%"><b>Soldiers</b></td>
                <td width="5%"><b>Tot</b></td>
                <td width="5%"><b>Inj</b></td>
                <td width="5%"><b>Dead</b></td>
                <td width="5%"><b>Capt</b></td>
            </tr>
            <tr>
                <td>Commanders</td>
                <td *ngFor="let item of com1">{{ item }}</td>
                <td></td>
                <td>Commanders</td>
                <td *ngFor="let item of com2">{{ item }}</td>
            </tr>
            <tr>
                <td>Heroes</td>
                <td *ngFor="let item of her1">{{ item }}</td>
                <td></td>
                <td>Heroes</td>
                <td *ngFor="let item of her2">{{ item }}</td>
            </tr>
            <tr>
                <td>Artillery</td>
                <td *ngFor="let item of rgd1">{{ item }}</td>
                <td></td>
                <td>Artillery</td>
                <td *ngFor="let item of rgd2">{{ item }}</td>
            </tr>
            <tr>
                <td>Cavalry</td>
                <td *ngFor="let item of cav1">{{ item }}</td>
                <td></td>
                <td>Cavalry</td>
                <td *ngFor="let item of cav2">{{ item }}</td>
            </tr>
            <tr>
                <td>Infantry</td>
                <td *ngFor="let item of inf1">{{ item }}</td>
                <td></td>
                <td>Infantry</td>
                <td *ngFor="let item of inf2">{{ item }}</td>
            </tr>
            <tr>
                <td colspan="5" [innerHtml]="result.msg1"></td>
                <td></td>
                <td colspan="5" [innerHtml]="result.msg2"></td>
            </tr>
        </table>    
    </div>
</div>
