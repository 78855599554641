import { Injectable } from '@angular/core';
import { ServiceNotReadyError } from '@app/exceptions/service-not-ready.error';
import { ICityService } from '@app/services/icity.service';
import { ICity } from '@app/interfaces/city.interface';
import { GridLocation, IGridLocation } from '../models/gridlocation.model';
import { IShipType } from '../models/shiptype.model';

@Injectable({
  providedIn: 'root'
})
export class NavyCacheService {
  private grid: IGridLocation[] = null;
  private shipTypes: IShipType[] = null;
  constructor(protected CityService: ICityService) { }

  public load(data: any) {
    this.grid = data.grid.map(o => {
      var gridLocation = Object.assign(new GridLocation(), o as GridLocation);
      gridLocation.Ports = [];

      for (var port of data.ports.filter(o => o.GridId == gridLocation.Id)) {
        gridLocation.Ports.push(this.CityService.getCityById(port.CityId));
      }

      return gridLocation;
    });
    this.shipTypes = data.shipTypes;

    //this.ports = data.ports;
  }

  public GetGridLocations(): IGridLocation[] {
    if (this.grid === null)
      throw new ServiceNotReadyError("Navy cache service not ready yet");

    return this.grid;
  }

  public GetGridLocationById(id: number): IGridLocation {
    if (this.grid === null)
      throw new ServiceNotReadyError("Navy cache service not ready yet");

    return this.grid.find(o => o.Id == id);
  }

  public GetGridLocationByCoordinates(x: number, y: number): IGridLocation {
    if (this.grid === null)
      throw new ServiceNotReadyError("Navy cache not ready yet");

    return this.grid.find(o => o.X == x && o.Y == y);
  }

  public GetShipTypeById(id: number): IShipType {
    if (this.shipTypes === null)
      throw new ServiceNotReadyError("Navy cache service not ready yet");

    return this.shipTypes.find(o => o.Id == id);
  }

  public GetCityPorts(city: ICity) {
    return this.grid.filter(o => o.Ports.find(p => p == city));
  }

  public GetGridLocationBorders(location: IGridLocation) {
    if (location == null)
      return null;

    return this.grid.filter(o => (o.X == location.X && o.Y == location.Y - 1)
      || (o.X == location.X && o.Y == location.Y + 1)
      || (o.Y == location.Y && o.X == location.X - 1)
      || (o.Y == location.Y && o.X == location.X + 1));
  }
}
