import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AgeService } from '@app/services/age.service';
import { TimeService } from '@app/services/time.service';

import { Age } from '@app/models/age.model';
import { NgIf } from '@angular/common';

@Component({
    selector: 'tlk-age-statistics',
    templateUrl: './age-statistics.component.html',
    styleUrls: ['./age-statistics.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class AgeStatisticsComponent implements OnInit {
  age: Age = <Age>{};
  ageSubscription: Subscription;
  daysPassed: number;

  DaysLeft: number;
  HoursLeft: number;
  MinutesLeft: number;

  constructor(private ageService: AgeService,
              public TimeService: TimeService) { }

  ngOnInit(): void {
    this.ageSubscription = this.ageService.Age.subscribe(age => {
      this.age = age;
      this.daysPassed = Math.ceil((-this.age.ticksToStart + 1) / 48);

      this.CalculateTimeRemaining();
    });

    this.TimeService.SecondsLeftChanged.subscribe(o => {
        this.CalculateTimeRemaining();
    });
  }

  CalculateTimeRemaining() {
      var ticksLeft = this.age.ticksToEnd - 1;

      this.DaysLeft = Math.floor(ticksLeft / 48);
      ticksLeft -= this.DaysLeft * 48;

      this.HoursLeft = Math.floor(ticksLeft / 2);
      ticksLeft -= this.HoursLeft * 2;

      var secondsLeftToTick = this.TimeService.SecondsToTick;
      var minutesLeftToTick = Math.ceil(secondsLeftToTick / 60);
      this.MinutesLeft = minutesLeftToTick + (ticksLeft * 30);
  }

  ngOnDestroy() {
    this.ageSubscription.unsubscribe();
  }

}
