import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ifnull',
    standalone: true
})
export class IfNullPipe implements PipeTransform {
  transform(value: any, replacement: any = 0): any {
    if (value == null)
      return replacement;

    return value;
  }
}
