import {Component, OnDestroy, OnInit} from '@angular/core';
import {PageService} from '@app/services/page.service';
import {faBars, faTimes} from '@fortawesome/pro-solid-svg-icons';
import {NavService} from '@app/shared/components/nav/nav.service';
import { KeyboardShortcutService } from './services/keyboard-shortcut.service';
import { PollingService } from './services/polling.service';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SnackbarComponent } from './shared/components/snackbar/snackbar.component';
import { NavComponent } from './shared/components/nav/nav.component';
import { NgIf, NgStyle } from '@angular/common';
import { UserbarComponent } from './shared/components/userbar/userbar.component';

@Component({
    selector: 'tlk-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [UserbarComponent, NgIf, NavComponent, NgStyle, RouterOutlet, SnackbarComponent]
})
export class AppComponent implements OnInit, OnDestroy {
  navShown = false;

  // Font Awesome icons
  faTimes = faTimes;
  faBars = faBars;

  navSubscription: Subscription;

  constructor(
    public page: PageService,
    public navService: NavService,
    private keyboardShortcutService: KeyboardShortcutService,
    private pollingService: PollingService,
    private router: Router
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(
      (x: NavigationEnd) => {
        this.hideNav();
        this.scrollToTop();
      });
  }

  ngOnInit() {
    this.navSubscription = this.navService.menuItemClicked$.subscribe(() => {
     this.hideNav();
     //this.scrollToTop();
    });

    this.keyboardShortcutService.listen();
    this.pollingService.poll();
  }

  ngOnDestroy() {
    this.navSubscription.unsubscribe();
  }

  onResize() {
    this.page.checkWidth();
  }

  toggleNav(value: boolean = null) {
    if (value == null)
      value = !this.navShown;

    this.navShown = value;
  }

  showNav() {
    this.navShown = true;
  }

  hideNav() {
    this.navShown = false;
  }

  scrollToTop() {
    if (window.scrollY > 100) {
      window.scroll({
        top: 100,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
}
