import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { timer } from "rxjs";
import { AgeService } from "./age.service";
import { AuthService } from "./auth.service";
import { ICityService } from "./icity.service";
import { INationService } from "./ination.service";
import { IRankService } from "./irank.service";
import { PlayerService } from "./player.service";
import { UserService } from "./user.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class PollingService {
  accountInterval = 5000; // poll every 5 seconds
  rankingInterval = 1000 * 60 * 5; // poll every 5 minutes

  public forceSkipPolling = false;

  constructor(
    private http: HttpClient,
    private playerService: PlayerService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) { }

  public poll() {
    let skipPolling = true;

    // do not poll when logged out
    this.authService.getLoginStatus().subscribe(loggedIn => {
      skipPolling = !loggedIn;
    });

    timer(0, this.accountInterval).subscribe(() => {
      if (skipPolling || this.forceSkipPolling) {
        return;
      }

      this.http.post<any>('/json.php', {
        page: 'polling',
        request: 'AccountData'
      }).subscribe(response => {
        if (response.updatedData.dead) {
          this.router.navigate(['/city']);
          this.playerService.setData({
            authority: response.updatedData.authority,
            gold: response.updatedData.gold,
            hc: response.updatedData.hc,
            movePoints: response.updatedData.moves,
            xp: response.updatedData.xp,
            nationId: response.updatedData.nationId,
            rankId: response.updatedData.rankId,
            pvpProtection: response.updatedData.pvpProtection,
            commanderId: response.updatedData.commanderId,
            commanderName: response.updatedData.commanderName,
            damage: response.updatedData.damage,
            dead: response.updatedData.dead
          });
        }
        else {
          this.playerService.setData({
            authority: response.updatedData.authority,
            gold: response.updatedData.gold,
            hc: response.updatedData.hc,
            movePoints: response.updatedData.moves,
            xp: response.updatedData.xp,
            cityId: response.updatedData.cityId,
            citySize: response.updatedData.citySize,
            nationId: response.updatedData.nationId,
            rankId: response.updatedData.rankId,
            pvpProtection: response.updatedData.pvpProtection,
            commanderId: response.updatedData.commanderId,
            commanderName: response.updatedData.commanderName,
            damage: response.updatedData.damage,
            dead: response.updatedData.dead
          });
        }       

        this.userService.setData({
          adminPermissionsCount: response.updatedData.adminPermissions,
          nobility: response.updatedData.nobility,
          safeMode: response.updatedData.safeMode,
          unreadMessages: response.updatedData.unreadMessages,
          unreadBattleCount: response.updatedData.unreadBattleCount
        });        
      });
    });

    timer(0, this.rankingInterval).subscribe(() => {
      if (skipPolling || this.forceSkipPolling) {
        return;
      }

      this.http.post<any>('/json.php', {
        page: 'polling',
        request: 'PlayerStats'
      }).subscribe(response => {
        this.playerService.setRanking({
          rankingPlace: response.rankingPlace,
          rankingScore: response.rankingScore,
          pvpPlace: response.pvpPlace,
          pvpScore: response.pvpScore,
          sfPlace: response.sfPlace,
          sfScore: response.sfScore
        });
      });
    });
  }
}
