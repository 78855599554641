import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { PlayerService } from '@app/services/player.service';
import { Player } from '@app/models/player.model';
import { UserService } from '@app/services/user.service';
import { User } from '@app/models/user.model';
import { PageService } from '@app/services/page.service';
import { faBars, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { StorageKey } from '@app/enums/storage-key.enum';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AgeStatisticsComponent } from '../age-statistics/age-statistics.component';
import { RouterLink } from '@angular/router';
import { CountryFlagComponent } from '../country-flag/country-flag.component';
import { UserRankComponent } from '../user-rank/user-rank.component';
import { NgIf, NgClass } from '@angular/common';
import { GameclockComponent } from '../game-clock/game-clock.component';

@Component({
    selector: 'tlk-userbar',
    templateUrl: './userbar.component.html',
    styleUrls: ['./userbar.component.scss'],
    standalone: true,
    imports: [GameclockComponent, NgIf, UserRankComponent, CountryFlagComponent, RouterLink, AgeStatisticsComponent, NgClass, FaIconComponent]
})
export class UserbarComponent implements OnInit, OnDestroy {
  // Font Awesome icons
  faTimes = faTimes;
  faBars = faBars;

  private _showNav: boolean;
  private _messageCount: number = 0;
  private _battleResultCount: number = 0;

  @Input() get ShowNav(): boolean {
    return this._showNav;
  }
  set ShowNav(value: boolean) {
    if (this._showNav == value)
      return;

    this._showNav = value;
    this.ShowNavChange.emit(value);
  }
  @Output() ShowNavChange = new EventEmitter<boolean>();

  @Input()
  showPlayer: boolean = false;
  @Input()
  showStatistics: boolean = false;
  @Input()
  showClock: boolean = false;

  playerSubscription: Subscription;
  player: Player = <Player>{};

  userSubscription: Subscription;
  public get messageCount(): number {
    return this._messageCount;
  }

  public set messageCount(value: number) {
    this._messageCount = value;
  }

  public get battleResultCount(): number {
    return this._battleResultCount;
  }

  public set battleResultCount(value: number) {
    this._battleResultCount = value;
  }

  openCheats = false;
  soundsLastPlayed: Date;

  constructor(
    private playerService: PlayerService,
    private userService: UserService,
    public page: PageService
  ) { }

  ngOnInit() {        
    this.playerSubscription = this.playerService.getData().subscribe(data => {
      this.player = data;
    });

    this.userSubscription = this.userService.getData().subscribe(data => {
      // check for change
      if (data.playSounds) {
        if (data.unreadMessages > this.messageCount) {
          const messageAudio = new Audio();
          messageAudio.src = "/assets/sounds/msg-alert3.mp3";
          messageAudio.load();
          messageAudio.play();
        }
        if (data.unreadBattleCount > this.battleResultCount) {
          const battleAudio = new Audio();
          battleAudio.src = "/assets/sounds/battle-alert2.mp3";
          battleAudio.load();
          battleAudio.play();
        }
      }

      this.messageCount = data.unreadMessages;
      this.battleResultCount = data.unreadBattleCount;
    });
  }

  ngOnDestroy() {
    this.playerSubscription.unsubscribe();
  }
}
