import { Component, Input } from '@angular/core';
import { faShieldSlash } from '@fortawesome/pro-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tlk-protection',
    templateUrl: './component.html',
    styleUrls: ['./component.scss'],
    standalone: true,
    imports: [NgbTooltip, FaIconComponent]
})
export class StatsProtectionComponent {
  public icon = faShieldSlash;

  @Input()
  value: string;

  constructor(
  ) { }
}
