import { TerrainBonus } from './terrain-bonus.model';
import { INation } from '../interfaces/nation.interface';

export class Nation implements INation {
  Id: number;
  Name: string;
  Adjective: string;
  Color: string;
  SecondaryColor: string;
  Continent: string;
  Pattern: string;
  Map: string;
  Terrains: TerrainBonus[];
  HasCapital: boolean;
}
