import { Component, Input, OnInit } from '@angular/core';
import { Age } from '@app/models/age.model';
import { Player } from '@app/models/player.model';
import { AgeService } from '@app/services/age.service';
import { PlayerService } from '@app/services/player.service';
import { Subscription } from 'rxjs';
import { ToLocalPipe } from '../../pipes/to-local.pipe';
import { NgIf, NgFor } from '@angular/common';
import { CountryFlagComponent } from '../country-flag/country-flag.component';

@Component({
    selector: 'tlk-full-battle-result',
    templateUrl: './full-battle-result.component.html',
    styleUrls: ['./full-battle-result.component.scss'],
    standalone: true,
    imports: [CountryFlagComponent, NgIf, NgFor, ToLocalPipe]
})
export class FullBattleResultComponent {
  @Input()
  result: any;
  player: number;
  com1: string[] = [];
  her1: string[] = [];
  inf1: string[] = [];
  cav1: string[] = [];
  rgd1: string[] = [];
  com2: string[] = [];
  her2: string[] = [];
  inf2: string[] = [];
  cav2: string[] = [];
  rgd2: string[] = [];

  playerSubscription: Subscription;
  ageSubscription: Subscription;
  age: Age;
  playerObj: Player;
  
  constructor(
    private ageService: AgeService,
    private playerService: PlayerService
  ) { }

  ngOnInit() {
    this.ageSubscription = this.ageService.Age.subscribe(data => {
      this.age = data;
    });
    this.playerSubscription = this.playerService.getData().subscribe(data => {
      this.playerObj = data;
      this.initializePlayer();
    });
  }

  ngOnChanges(): void {
    this.initializePlayer();
    
    this.com1 = this.result.com1.split('_');
    this.her1 = this.result.her1.split('_');
    this.inf1 = this.result.inf1.split('_');
    this.cav1 = this.result.cav1.split('_');
    this.rgd1 = this.result.rgd1.split('_');

    this.com2 = this.result.com2.split('_');
    this.her2 = this.result.her2.split('_');
    this.inf2 = this.result.inf2.split('_');
    this.cav2 = this.result.cav2.split('_');
    this.rgd2 = this.result.rgd2.split('_');
  }

  initializePlayer() {
    this.player = 0;
    if (this.playerObj) {
      if (this.result.player1_id === this.playerObj.commanderId || this.result.player1_id === this.playerObj.id) {
        this.player = 1;
        this.result.msg1 = this.result.msg1.replace(/<hr>/g, '<hr class="default-hr">');
      }
      else if (this.result.player2_id === this.playerObj.commanderId || this.result.player2_id === this.playerObj.id) {
        this.player = 2;
        this.result.msg2 = this.result.msg2.replace(/<hr>/g, '<hr class="default-hr">');
      }
    }
  }

  ngOnDestroy() {
    this.playerSubscription.unsubscribe();
    this.ageSubscription.unsubscribe();
  }
}
