import { Component, OnInit, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { LuxonToLocalPipe } from '../../pipes/luxon-to-local.pipe';

@Component({
    selector: 't',
    templateUrl: './time.component.html',
    styleUrls: ['./time.component.scss'],
    standalone: true,
    imports: [LuxonToLocalPipe]
})
export class TimeComponent implements OnInit {
  private LongTimeRegex = RegExp('^(\\d+):(\\d+):(\\d+)$');
  private ShortTimeRegex = RegExp('^(\\d+):(\\d+)$');

  private _inputTime: string = null;

  get T(): string {
    return this._inputTime;
  }

  @Input()
  set T(value: string) {
    if (value == null)
      return;

    if (this.I === null) {
      if (this.LongTimeRegex.test(value))
        this.I = "H:mm:ss";
      else if(this.ShortTimeRegex.test(value))
          this.I = "H:mm";
    }

    this.L = DateTime.fromFormat(value, this.I, { zone: 'utc' });
  }

  @Input()
  public F: string = 'd MMM H:mm';

  @Input()
  public I: string = 'yyyy-LL-dd H:mm:ss';

  @Input()
  public L: DateTime = null;

  constructor() { }

  ngOnInit() {

  }
}
