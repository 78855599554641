import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AgeService } from '@app/services/age.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActiveAgeGuard  {
  constructor(
    private ageService: AgeService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>(async (resolve, reject) => {
      this.ageService.Age.pipe(take(1)).subscribe(data => {
        if (data.ticksToStart <= 0 && data.ticksToEnd > 0) {
            resolve(true);
        }
        else {
            this.router.navigate(['/misc/age-break']);
            resolve(false);
        }
      }, error => reject(error));
    });
  }

}
