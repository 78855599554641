<div class="accent MedievalSharp" style="font-size: 1.15rem;">    
    <div *ngIf="age.ticksToStart > 0">
        Age {{ age.age }} <span class="text-white">will start in {{ age.ticksToStart }} Tick<span *ngIf="age.ticksToStart != 1">s</span></span>
    </div>
    <div *ngIf="age.ticksToStart <= 0 && age.ticksToEnd > 0">
        Age {{ age.age }} <span class="text-white">- Day {{ daysPassed }}
        <span *ngIf="DaysLeft < 7">({{DaysLeft}} Day<span *ngIf="DaysLeft!=1">s</span> {{HoursLeft}} Hr {{MinutesLeft}} Min Left)</span>
      </span>
    </div>
    <div *ngIf="age.ticksToEnd <= 0">
        Age has ended 
    </div>
</div>
