import { HttpClient } from '@angular/common/http';
import { ISoldierType } from '@app/interfaces/soldiertype.interface';
import { ISoldierLevel } from '@app/interfaces/soldierlevel.interface';
import { Observable } from 'rxjs';
import { Soldier } from '@app/models/soldier.model';

export abstract class ISoldierService {
  constructor(
    protected http: HttpClient
  ) { }

  abstract load(levels: ISoldierLevel[], types: ISoldierType[]);
  abstract getSoldierTypes(): ISoldierType[];
  abstract getSoldierType(id: number): ISoldierType;
  abstract getSoldierLevels(): ISoldierLevel[];
  abstract getSoldierLevel(level: number, stage: number): ISoldierLevel;
  abstract dismiss(units: Soldier[]): Observable<void>;
  abstract removeDead(units: Soldier[]): Observable<void>;
  abstract changeName(unitId: number, unitName: string): Observable<void>;
  abstract promoteAll(injured: boolean, order: string): Observable<any>;
  abstract promoteGroup(currentTypeId: number, currentLevel: number, promotionType: number): Observable<any>;
  abstract promoteUnit(unitId: number, promotionType: number): Observable<any>;
}
