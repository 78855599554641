import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

// components
import { HeroWeaponIconComponent } from './components/hero-weapon-icon/hero-weapon-icon.component';
import { HeroIconComponent } from './components/hero-icon/hero-icon.component';
import { SoldierIconComponent } from './components/soldier-icon/soldier-icon.component';
import {GoldComponent} from './components/gold/gold.component';
import {HtmlDropdownComponent} from './components/html-dropdown/html-dropdown.component';

// pipes
import {AbsoluteValuePipe} from './pipes/absolute-value.pipe';
import {AddSignPipe} from './pipes/add-sign.pipe';
import {CountPipe} from './pipes/count.pipe';
import {DurationPipe} from './pipes/duration.pipe';
import {FilterByPipe} from './pipes/filter-by.pipe';
import {FilterByPropertyPipe} from './pipes/filter-by-property.pipe';
import { GroupByPipe } from './pipes/group-by.pipe';
import { IfNullPipe } from './pipes/ifnull.pipe';
import {LimitPipe} from './pipes/limit.pipe';
import {SortPipe} from './pipes/sort.pipe';
import {StripStarsPipe} from './pipes/strip-stars.pipe';
import {TicksToDaysPipe} from './pipes/ticks-to-days.pipe';
import {OnlinePipe} from './pipes/online.pipe';
//export { OnlinePipe } from './pipes/online.pipe';
import {ToLocalPipe} from './pipes/to-local.pipe';
//export { ToLocalPipe } from './pipes/to-local.pipe';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import {SkeletonComponent} from './components/skeleton/skeleton.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {MessageComponent} from './components/messages/message/message.component';
import {NewMessageComponent} from './components/messages/new-message/new-message.component';
import {PlayerComponent} from './components/player/player.component';
import {MessageService} from './components/messages/message.service';
import {TableComponent} from './components/table/table.component';
import {TableRowComponent} from './components/table/table-row/table-row.component';
import {TableCellComponent} from './components/table/table-cell/table-cell.component';
import {BarracksSoldierCardComponent} from '../modules/city/pages/barracks/components/barracks-soldier-card/barracks-soldier-card.component';
import {TabsComponent} from './components/tabs/tabs.component';
import {TabComponent} from './components/tabs/tab/tab.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NgxLoadingModule } from 'ngx-loading';
import { LuxonToLocalPipe } from './pipes/luxon-to-local.pipe';
import { ReplaceWithLocalPipe } from './pipes/replace-with-local.pipe';
import { FullBattleResultComponent } from './components/full-battle-result/full-battle-result.component';
import { ArmyIconComponent } from './components/army-icon/army-icon.component';
import { GameclockComponent } from './components/game-clock/game-clock.component';
import { NavComponent } from './components/nav/nav.component';
import { MenuGroupComponent } from './components/nav/menu-group/menu-group.component';
import { MenuItemComponent } from './components/nav/menu-item/menu-item.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { UserbarComponent } from './components/userbar/userbar.component';
import { CountryFlagComponent } from './components/country-flag/country-flag.component';
import { UserRankComponent } from './components/user-rank/user-rank.component';
import { CheatsComponent } from './components/cheats/cheats.component';
import { SvgComponent } from './components/svg/svg.component';
import { TimeComponent } from './components/time/time.component';
import { JoinPipe } from './pipes/join.pipe';
import { SetDifferencePipe } from './pipes/set-difference.pipe';
import { AgeStatisticsComponent } from './components/age-statistics/age-statistics.component';
import { InfoButtonComponent } from './components/info-button/info-button.component';
import { SortByUnitLevelPipe } from './pipes/sort-by-unit-level';
import { SumPipe } from './pipes/sum.pipe';
import { StatsHealthComponent } from './components/stats/health/component';
import { StatsStrengthComponent } from './components/stats/strength/component';
import { StatsRangedStrengthComponent } from './components/stats/rangedstrength/component';
import { StatsRangedAccuracyComponent } from './components/stats/rangedaccuracy/component';
import { StatsAuthorityComponent } from './components/stats/authority/component';
import { CeilPipe } from './pipes/ceil.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StatsPromotionComponent } from './components/stats/promotion/component';
import { StatsTerrainBonusComponent } from './components/stats/terrainbonus/component';
import { StatsInjuredComponent } from './components/stats/injured/component';
import { SafePipe } from './pipes/safe';
import { DistinctPipe } from './pipes/distinct.pipe';
import { StatsMovepointsComponent } from './components/stats/movepoints/component';
import { StatsExperienceComponent } from './components/stats/experience/component';
import { StatsProtectionComponent } from './components/stats/protection/component';
import { StatsPikeComponent } from './components/stats/pike/component';
import { StatsOfficersComponent } from './components/stats/officers/component';
import { TicksInjuredPipe } from './pipes/ticks-injured';
import { StatsHeroesComponent } from './components/stats/heroes/component';
import { StatsSoldiersComponent } from './components/stats/soldiers/component';
import { DevCheatsPopup } from './popups/dev-cheats/dev-cheats.popup';

// Services
export { MessageService } from './components/messages/message.service';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        NgxSkeletonLoaderModule,
        FontAwesomeModule,
        EditorModule,
        NgxLoadingModule,
        NgbModule,
        GameclockComponent,
        NavComponent,
        MenuGroupComponent,
        MenuItemComponent,
        SnackbarComponent,
        UserbarComponent,
        CountryFlagComponent,
        UserRankComponent,
        CheatsComponent,
        SvgComponent,
        TimeComponent,
        GoldComponent,
        HtmlDropdownComponent,
        ProgressbarComponent,
        AbsoluteValuePipe,
        AddSignPipe,
        CountPipe,
        DurationPipe,
        FilterByPipe,
        FilterByPropertyPipe,
        GroupByPipe,
        IfNullPipe,
        LimitPipe,
        OnlinePipe,
        SortPipe,
        StripStarsPipe,
        TicksToDaysPipe,
        TicksInjuredPipe,
        ToLocalPipe,
        LuxonToLocalPipe,
        ReplaceWithLocalPipe,
        JoinPipe,
        SetDifferencePipe,
        SortByUnitLevelPipe,
        SumPipe,
        CeilPipe,
        SafePipe,
        DistinctPipe,
        HeroIconComponent,
        HeroWeaponIconComponent,
        SkeletonComponent,
        SoldierIconComponent,
        PaginationComponent,
        MessageComponent,
        NewMessageComponent,
        PlayerComponent,
        TableComponent,
        TableRowComponent,
        TableCellComponent,
        BarracksSoldierCardComponent,
        TabsComponent,
        TabComponent,
        LoaderComponent,
        FullBattleResultComponent,
        ArmyIconComponent,
        AgeStatisticsComponent,
        InfoButtonComponent,
        StatsHealthComponent,
        StatsStrengthComponent,
        StatsRangedStrengthComponent,
        StatsRangedAccuracyComponent,
        StatsPikeComponent,
        StatsAuthorityComponent,
        StatsPromotionComponent,
        StatsTerrainBonusComponent,
        StatsInjuredComponent,
        StatsMovepointsComponent,
        StatsOfficersComponent,
        StatsHeroesComponent,
        StatsSoldiersComponent,
        StatsExperienceComponent,
        StatsProtectionComponent,
        DevCheatsPopup
    ],
    providers: [
        MessageService,
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ],
    exports: [
        GameclockComponent,
        NavComponent,
        MenuGroupComponent,
        MenuItemComponent,
        SnackbarComponent,
        UserbarComponent,
        CountryFlagComponent,
        UserRankComponent,
        CheatsComponent,
        SvgComponent,
        TimeComponent,
        GoldComponent,
        HtmlDropdownComponent,
        ProgressbarComponent,
        AbsoluteValuePipe,
        AddSignPipe,
        CountPipe,
        DurationPipe,
        FilterByPipe,
        FilterByPropertyPipe,
        GroupByPipe,
        IfNullPipe,
        LimitPipe,
        OnlinePipe,
        SortPipe,
        StripStarsPipe,
        TicksToDaysPipe,
        TicksInjuredPipe,
        ToLocalPipe,
        LuxonToLocalPipe,
        ReplaceWithLocalPipe,
        JoinPipe,
        SortByUnitLevelPipe,
        SetDifferencePipe,
        SumPipe,
        CeilPipe,
        SafePipe,
        DistinctPipe,
        HeroIconComponent,
        HeroWeaponIconComponent,
        SoldierIconComponent,
        SkeletonComponent,
        PaginationComponent,
        MessageComponent,
        NewMessageComponent,
        TableComponent,
        TableRowComponent,
        TableCellComponent,
        BarracksSoldierCardComponent,
        TabsComponent,
        TabComponent,
        LoaderComponent,
        FullBattleResultComponent,
        ArmyIconComponent,
        AgeStatisticsComponent,
        InfoButtonComponent,
        StatsHealthComponent,
        StatsMovepointsComponent,
        StatsOfficersComponent,
        StatsHeroesComponent,
        StatsSoldiersComponent,
        StatsStrengthComponent,
        StatsRangedStrengthComponent,
        StatsRangedAccuracyComponent,
        StatsPikeComponent,
        StatsAuthorityComponent,
        StatsPromotionComponent,
        StatsTerrainBonusComponent,
        StatsInjuredComponent
    ]
})
export class SharedModule { }
