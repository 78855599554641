import {Component, Input, OnInit} from '@angular/core';
import {IRank} from '@app/interfaces/rank.interface';
import {IRankService} from '@app/services/irank.service';
import {StripStarsPipe} from '@app/shared/pipes/strip-stars.pipe';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { NgIf } from '@angular/common';

@Component({
    selector: 'tlk-user-rank',
    templateUrl: './user-rank.component.html',
    styleUrls: ['./user-rank.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class UserRankComponent {
  @Input() public Rank: IRank;
  @Input() public Female: boolean;

  @Input() public set OriginalRank(value: string) {
    this.Rank = this.RankService.getRankByName(value);
  }

  //SvgUrl: SafeResourceUrl;
  PngUrl: SafeResourceUrl;

  constructor(
    private RankService: IRankService,
    private DomSanitizer: DomSanitizer
  ) {}

  ngOnChanges() {
    this.PngUrl = this.DomSanitizer.bypassSecurityTrustResourceUrl('/assets/images/ranks/png/' + this.RankName + '.png');
    //this.SvgUrl = this.DomSanitizer.bypassSecurityTrustResourceUrl('/assets/images/ranks/svg/' + this.RankName + '.svg');
  }

  get RankName(): string {
    if (this.Rank) {
      return this.Rank.name[this.Female ? 'female' : 'male'];
    }

    return null;
  }
}
