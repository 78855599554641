export enum StorageKey {
  AuthInfo = 'auth-info',
  Ranks = 'ranks',
  Cities = 'cities',
  Roads = 'roads',
  Nations = 'nations',
  Age = 'age',
  Cache = 'cache',
  CityWalls = 'city-walls',

}
