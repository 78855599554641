import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageKey } from '@app/enums/storage-key.enum';
import { ServiceNotReadyError } from '@app/exceptions/service-not-ready.error';
import { ISoldierService } from './isoldier.service';
import { ISoldierLevel } from '@app/interfaces/soldierlevel.interface';
import { ISoldierType } from '@app/interfaces/soldiertype.interface';
import { SoldierType } from '@app/models/soldiertype.model';
import { Observable } from 'rxjs';
import { Soldier } from '@app/models/soldier.model';

@Injectable({
  providedIn: 'root'
})
export class SoldierService extends ISoldierService {
  private soldiertypes: SoldierType[];
  private soldierlevels: ISoldierLevel[];

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  public load(levels: ISoldierLevel[], types: ISoldierType[]) {
    this.soldiertypes = types.map(rank => Object.assign(new SoldierType(), rank as SoldierType));
    this.soldierlevels = levels;
  }

  public fetchSoldierTypes(): Observable<ISoldierType[]> {
    return this.http.post<ISoldierType[]>('/json.php', {
      page: 'general',
      request: 'getSoldierTypes'
    });
  }

  public fetchSoldierLevels(): Observable<ISoldierLevel[]> {
    return this.http.post<ISoldierLevel[]>('/json.php', {
      page: 'general',
      request: 'getSoldierLevels'
    });
  }

  public getSoldierTypes(): ISoldierType[] {
    if (this.soldiertypes == null)
      throw new ServiceNotReadyError("Soldier service not ready yet");

    return this.soldiertypes;
  }

  public getSoldierType(id: number): ISoldierType {
    if (this.soldiertypes == null)
      throw new ServiceNotReadyError("Soldier service not ready yet");

    return this.soldiertypes[this.soldiertypes.findIndex(x => x.Id === id)];
  }

  public getSoldierLevels(): ISoldierLevel[] {
    if (this.soldierlevels == null)
      throw new ServiceNotReadyError("Soldier service not ready yet");

    return this.soldierlevels;
  }

  public getSoldierLevel(level: number, stage:number): ISoldierLevel {
    if (this.soldierlevels == null)
      throw new ServiceNotReadyError("Soldier service not ready yet");

    return this.soldierlevels[this.soldierlevels.findIndex(x => x.Level == level && x.Stage == stage)];
  }

  public dismiss(units: Soldier[]): Observable<void> {
    return this.http.post<void>('/json.php', {
        page: 'encampment',
        request: 'Dismiss',
        params: {
            heroIds: [],
            soldierIds: units.map(x => x.Id)
        }
    });
  }

  public removeDead(units: Soldier[]): Observable<void> {
    return this.http.post<void>('/json.php', {
        page: 'encampment',
        request: 'RemoveFromGraveyard',
        params: {
            heroIds: [],
            soldierIds: units.map(x => x.Id)
        }
    });
  }

  public changeName(unitId: number, unitName: string): Observable<void> {
    return this.http.post<void>('/json.php', {
        page: 'encampment',
        request: 'ChangeSoldierName',
        params: { unitId, unitName }
    });
  }

  public promoteAll(injured: boolean, order: string): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'encampment',
      request: 'PromoteAll',
      params: { injured, order }
    });
  }

  public promoteGroup(currentTypeId: number, currentLevel: number, promotionType: number): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'encampment',
      request: 'PromoteUnitType',
      params: { injured: true, order: '1', currentLevel, currentTypeId, promotionType }
    });
  }

  public promoteUnit(unitId: number, promotionType: number): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'encampment',
      request: 'PromoteUnit',
      params: { unitId, promotionType }
    });
  }
}
