import { serviceInjector } from '../services/serviceinjector';

import { ICityService } from '../services/icity.service';
import { INationService } from '../services/ination.service';
import { IRankService } from '../services/irank.service';

import { IPlayer } from '../interfaces/player.interface';
import { INation } from '../interfaces/nation.interface';
import { IRank } from '../interfaces/rank.interface';
import { ICity } from '../interfaces/city.interface';

export class Player implements IPlayer {
  private _cityId: number;
  private _nationId: number;
  private _rankId: number;
  private _city?: ICity = null;
  private _nation?: INation = null;
  private _rank?: IRank = null;

  id: number;
  userId: number;
  name: string;
  commanderName: string;
  commanderId: number;
  xp: number;
  gold: number;
  authority: number;
  hc: number;
  movePoints: number;
  damage: number;
  dead: boolean;
  pvpProtection: number;
  female: number;
  lastSuicide: string;

  public get rankedName(): string {
    return `${this.rank.name[this.female ? 'female' : 'male']} ${this.name}`;
  }

  public get cityId(): number {
    return this._cityId;
  }

  public set cityId(value: number) {
    if (this._cityId == value)
      return;

    this._cityId = value;

    if (value == null) {
      this.city = null;
    }
    else {
      try {
        this.city = serviceInjector().get(ICityService).getCityById(this._cityId);
      }
      catch (ServiceNotReadyError) {

      }
    }
  }

  public get city(): ICity {
    if (this._city == null)
      this._city = serviceInjector().get(ICityService).getCityById(this.cityId);

    return this._city;
  }

  public set city(value: ICity) {
    this._city = value;

    if (value == null)
      this.cityId = null;
    else
      this.cityId = value.id;
  }

  public get citySize(): string {
    return this.city.size;
  }

  public set citySize(value: string) {
    this.city.size = value;
  }

  public get nationId(): number {
    return this._nationId;
  }

  public set nationId(value: number) {
    if (this._nationId == value)
      return;

    this._nationId = value;

    if (value == null) {
      this.nation = null;
    }
    else {
      try {
        this.nation = serviceInjector().get(INationService).getNationById(this._nationId);
      }
      catch (ServiceNotReadyError) {

      }
    }
  }

  public get nation(): INation {
    if (this._nation == null)
      this._nation = serviceInjector().get(INationService).getNationById(this.nationId);

    return this._nation;
  }

  public set nation(value: INation) {
    this._nation = value;

    if (value == null)
      this.nationId = null;
    else
      this.nationId = value.Id;
  }

  public get rankId(): number {
    return this._rankId;
  }

  public set rankId(value: number) {
    if (this._rankId == value)
      return;

    this._rankId = value;

    if (value == null) {
      this.rank = null;
    }
    else {
      try {
        this.rank = serviceInjector().get(IRankService).getRankById(this._rankId);
      }
      catch (ServiceNotReadyError) {

      }
    }
  }

  public get rank(): IRank {
    if (this._rank == null)
      this._rank = serviceInjector().get(IRankService).getRankById(this.rankId);

    return this._rank;
  }

  public set rank(value: IRank) {
    this._rank = value;

    if (value == null)
      this.rankId = null;
    else
      this.rankId = value.id;
  }
}
