import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'tlk-tab',
    template: '<ng-content></ng-content>',
    styleUrls: ['./tab.component.scss'],
    standalone: true
})
export class TabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
