import { ICity } from "@app/interfaces/city.interface";

export interface IGridLocation {
  Id: number;
  X: number;
  Y: number;
  Ports: ICity[];
  Width: number;
  Height: number;
  Enabled: boolean;
  Coordinates: string;
}

export class GridLocation implements IGridLocation {
  public Id: number = null;
  public X: number = null;
  public Y: number = null;
  public Ports: ICity[] = [];
  public Width: number = 60;
  public Height: number = 60;
  public Enabled: boolean = true;
  public get Coordinates(): string {
    return String.fromCharCode(this.Y + 65) + (this.X + 1);
  }
}
