import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICity } from '@app/interfaces/city.interface';
import { ILandfill } from '../interfaces/landfill.interface';

export abstract class ICityService {
  constructor(
    protected http: HttpClient
  ) { }

  abstract setStaticData(cities: ICity[], landfill: ILandfill[]);
  //abstract loadDynamicData(): Promise<void>;
  abstract fetchCitiesDynamicData(): Observable<ICity[]>;
  abstract getCityById(id: number): ICity;
  abstract getCities(): ICity[];
  abstract getLandfill(): ILandfill[];
}
