  <div class="ColumnHeader">
    <tlk-game-clock class="clock" id="game-clock"></tlk-game-clock>
  </div>
  <div id="userbar">
    <tlk-user-rank *ngIf="!showPlayer && player != null" [Rank]="player.rank" [Female]="player.female == 1" style="margin-left:2px;"></tlk-user-rank>
    <div>
      <div class="userinfo" *ngIf="showPlayer && player != null">
        <tlk-country-flag [Nation]="player.nation"></tlk-country-flag>
        &nbsp;<tlk-user-rank [Rank]="player.rank" [Female]="player.female == 1"></tlk-user-rank>
        &nbsp;<span style="font-weight: 500; font-size: 1.25rem;">{{ player.rank.name[player.female ? 'female' : 'male'] }} </span>
        <a [routerLink]="['/user/profile/' + player.name]" class="accent" style="text-decoration: none;">
          <span style="font-weight: 500; font-size: 1.25rem;">{{ player.name }}</span>
        </a>
      </div>
      <tlk-age-statistics *ngIf="showStatistics"></tlk-age-statistics>
      <div *ngIf="showClock" style="display: flex; flex-direction: row;">
        <tlk-game-clock class="clock" id="game-clock" [showDelta]="false"></tlk-game-clock>
      </div>
    </div>
    <div class="usermenu flex-shrink-1">
      <ng-container *ngIf="player != null">
        <!--<a (click)="toggleCheats()" title="Cheats" *ngIf="page.isDevelopment">
          <tlk-svg fill="accent" url="/assets/fontawesome/cogs-solid.svg" class="userbaricon game-icon"></tlk-svg>
        </a>-->
        <a routerLink="/city" title="City Center (c)" (click)="ShowNav = false">
          <img src="/assets/images/ui/city.jpg" class="userbaricon">
        </a>
        <a routerLink="/map" title="Map (m)" (click)="ShowNav = false">
          <img src="/assets/images/ui/map.jpg" class="userbaricon">
        </a>
        <a routerLink="/user/messages" title="Messages (s)" (click)="ShowNav = false">
          <img src="/assets/images/ui/messages.jpg" class="userbaricon">
          <div class="shine" *ngIf="messageCount > 0"></div>
          <span id="unread-msg" class="counter" *ngIf="messageCount > 0">{{messageCount}}</span>
        </a>
        <a routerLink="/battle/your-battles" title="Your battle results" (click)="ShowNav = false">
          <img src="/assets/images/ui/battles.jpg" class="userbaricon" [ngClass]="{ 'shine': battleResultCount > 0}">
          <div class="shine" *ngIf="battleResultCount > 0"></div>
          <span id="new-battles" class="counter" *ngIf="battleResultCount > 0">{{battleResultCount}}</span>
        </a>
      </ng-container>
      <a *ngIf="!page.isDesktopView" title="Your battle results" (click)="ShowNav = !ShowNav" [ngClass]="{'toggled': ShowNav}">
        <fa-icon [icon]="faBars"></fa-icon>
      </a>
    </div>
  </div>
<!--<tlk-cheats *ngIf="openCheats"></tlk-cheats>-->
<!--<audio id="battle-alert" preload="auto">
    <source src="sounds/battle-alert2.mp3" type="audio/mp3">
    <source src="sounds/battle-alert2.wav" type="audio/wav">
</audio>
<audio id="msg-alert" preload="auto">
    <source src="sounds/msg-alert3.mp3" type="audio/mp3">
    <source src="sounds/msg-alert3.wav" type="audio/wav">
</audio>
<script>
            var playsounds = '1';
            var battlecount = -1;
            var messagecount = -1;
            var fromsession = 0;
            update_unread_counters(30000);
</script>-->
