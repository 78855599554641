import { INationService } from '../services/ination.service';
import { IRoadService } from '../services/iroad.service';

import { serviceInjector } from '../services/serviceinjector';

import { ICity } from '../interfaces/city.interface';
import { INation } from '../interfaces/nation.interface';
import { IRoad } from '../interfaces/road.interface';

export class City implements ICity {
  private _nationId: number;
  private _nation: INation = null;
  private _roads: IRoad[] = null;

  id: number;
  name: string;
  terrain: string;
  locked?: boolean;
  size: string;
  beach: boolean;
  x: number;
  y: number;
  xoffset: number;
  yoffset: number;
  polygon: string;
  enabled: boolean;

  public get nationId(): number {
    return this._nationId;
  }

  public set nationId(value: number) {
    if (this._nationId == value)
      return;

    this._nationId = value;

    if (value == null) {
      this.nation = null;
    }
    else {
      try {
        this.nation = serviceInjector().get(INationService).getNationById(this._nationId);
      }
      catch (ServiceNotReadyError) {

      }
    }
  }

  public get nation(): INation {
    if (this._nation === null)
      this._nation = serviceInjector().get(INationService).getNationById(this._nationId);

    return this._nation;
  }

  public set nation(value: INation) {
    this._nation = value;

    if (value == null)
      this.nationId = null;
    else
      this.nationId = value.Id;
  }

  public get roads(): IRoad[] {
    return serviceInjector().get(IRoadService).getCityRoads(this.id);
    //if (this._roads === null)
    //  this._roads = serviceInjector().get(IRoadService).getCityRoads(this.id);

    //return this._roads;
  }
}
