import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Observable, timer } from 'rxjs';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';

import { AgeService } from '@app/services/age.service';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  private _secondsToTick: number;
  public NextTick: string = '--';

  public Time: DateTime = null;
    public get SecondsToTick(): number {
        return this._secondsToTick;
    }
  public set SecondsToTick(value: number) {
    if (this._secondsToTick == value)
      return;

    if (value > this._secondsToTick)
      this.AgeService.GetAgeData();

    this._secondsToTick = value;
    this.SecondsLeftChanged.next(this._secondsToTick);

  }

  public TimeDifference: DateTime = 0;

  public SecondsLeftChanged: Subject<number> = new Subject<number>();

  constructor(
    private http: HttpClient,
    private AgeService: AgeService,
  ) { }

  private fetchServerTime(): Observable<{ CurrentTime: string, SecondsToTick: number }> {
    return this.http.post<{ CurrentTime: string, SecondsToTick: number }>('/json.php', {
      page: 'user',
      request: 'GetServerTime'
    });
  }

  private updateClock(): void {
    if (this.SecondsToTick != null) {
      var minutes = Math.floor(this.SecondsToTick / 60);
      var seconds = this.SecondsToTick % 60;
      var minutesString = ("0" + minutes).slice(-2);
      var secondsString = ("0" + seconds).slice(-2);
      this.NextTick = minutesString + ':' + secondsString;
    }
  }

  private increaseClock() {
    if (this.Time !== null)
    {
      this.Time = this.Time.plus({ seconds: 1 });
    }

    if (this.SecondsToTick !== null)
    {
      this.SecondsToTick -= 1;

      if (this.SecondsToTick < 0)
      {
        this.SecondsToTick += 30 * 60;
      }
    }

    this.updateClock();
  }

  public load(): Promise<void>
  {
    return new Promise<void>((resolve, reject) =>
    {
      interval(1000).subscribe(value => {
        this.increaseClock();
      });

      timer(0, 2 * 60 * 1000).subscribe(value => {
        this.fetchServerTime().subscribe(response => {
          let serverTime = DateTime.fromFormat(response.CurrentTime, 'yyyy-MM-dd H:mm:ss');
          this.Time = DateTime.fromFormat(response.CurrentTime, 'yyyy-MM-dd H:mm:ss', { zone: 'utc' });

          let deltaTime = this.Time - serverTime;

          if (deltaTime === 0)
            this.TimeDifference = null;
          else
            this.TimeDifference = (deltaTime > 0 ? "+" : "-") + new Date(deltaTime).toISOString().slice(11, -8);
          //console.info(this.Time - serverTime);

          this.SecondsToTick = response.SecondsToTick;

          this.updateClock();
          resolve();
        });
      });
    });
  }
}
