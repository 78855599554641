import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageKey } from '@app/enums/storage-key.enum';
import { Observable } from 'rxjs';
import { City } from '../models/city.model';
import { Landfill } from '../models/landfill.model';
import { Nation } from '../models/nation.model';
import { ICityService } from './icity.service';
import { INationService } from './ination.service';
import { IRankService } from './irank.service';
import { IRoadService } from './iroad.service';
import { ISoldierService } from './isoldier.service';
import { NavyCacheService } from '@app/modules/events/navy/services/navy.cache';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  blobData: any = {};

  constructor(
    private http: HttpClient,
    private cityService: ICityService,
    private rankService: IRankService,
    private nationService: INationService,
    private roadService: IRoadService,
    private soldierService: ISoldierService,
    private navyCacheService: NavyCacheService
  ) { }

  public loadBlob(url: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (url in this.blobData) {
        resolve(this.blobData[url]);
      }
      else {
        this.http.get(url, { responseType: 'text' }).subscribe(response => {
          this.blobData[url] = response;
          resolve(response);
        }, reject);
      }
    });
  }

  public load(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const data = sessionStorage.getItem(StorageKey.Cache);

      if (data) {
        const parsedData = JSON.parse(data);
        this.fetchData(parsedData.hash).subscribe(response => {
          // cache integrity verified
          if (response === 'OK') {
            this.loadServices(parsedData.data);
          }
          else {
            sessionStorage.setItem(StorageKey.Cache, JSON.stringify(response));
            this.loadServices(response.data);
          }          
          resolve();
        });
      }
      // no cache present
      else {
        this.fetchData('').subscribe(response => {
          sessionStorage.setItem(StorageKey.Cache, JSON.stringify(response));
          this.loadServices(response.data);          
          resolve();
        });
      }
    });
  }

  loadServices(data) {
    this.cityService.setStaticData(data.cities.map(o => Object.assign(new City(), o as City)), data.landfills.map(o => Object.assign(new Landfill(), o as Landfill)));
    this.nationService.load(data.nations.map(o => Object.assign(new Nation(), o as Nation)));
    this.rankService.load(data.ranks);
    this.roadService.load(data.roads);
    this.soldierService.load(data.soldierLevels, data.soldierTypes);

    if (data.navy != null) {
      this.navyCacheService.load(data.navy);
    }
  }

  fetchData(hash: string): Observable<any> {
    return this.http.post<any>('/json.php', {
      page: 'general',
      request: 'getFrontendData',
      params: { hash }
    });
  }
}
