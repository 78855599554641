import { Component, Input } from '@angular/core';
import { NgIf, NgClass, DecimalPipe } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tlk-terrainbonus',
    templateUrl: './component.html',
    styleUrls: ['./component.scss'],
    standalone: true,
    imports: [NgbTooltip, NgIf, NgClass, DecimalPipe]
})
export class StatsTerrainBonusComponent {

  @Input()
  Terrain: string;

  @Input()
  Bonus: number;

  constructor(
  ) { }

}
