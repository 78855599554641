import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'count',
    pure: true,
    standalone: true
})
export class CountPipe implements PipeTransform {
    transform(value: any[]): any {
        return value.length;
    }
}
