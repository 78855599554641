import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@app/models/user.model';
import { share, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Settings } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private data$: BehaviorSubject<User> = new BehaviorSubject<User>(<User>{});

  constructor(
    private http: HttpClient
  ) { }

  public getData(): Observable<User> {
    return this.data$.asObservable().pipe(
      share()
    );
  }

  public setData(data: Partial<User>): void {
    return this.data$.next({
      ...this.data$.getValue(),
      ...data
    });
  }

  public changeTimezone(timezone: string): Observable<void> {
    return this.http.post<void>('/json.php', {
      page: 'settings',
      request: 'saveTimezone',
      params: { timezone }
    }).pipe(
      tap(() => {
        this.setData({ timezone });
        Settings.defaultZone = timezone;
      })
    );
  }

  public changePassword(oldPassword: string, newPassword: string): Observable<void> {
    return this.http.post<void>('/json.php', {
      page: 'user',
      request: 'UpdatePassword',
      params: {
          oldPass: oldPassword,
          newPass: newPassword
      }
    });
  }

  public getAll(): Observable<User[]> {
    return this.http.post<User[]>('/json.php', {
      page: 'VIP',
      request: 'getUsers'
    });
  }
}
