import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'setDifference',
    pure: true,
    standalone: true
})
export class SetDifferencePipe implements PipeTransform {
    transform(value: any[], set: any[], property?: string): any[] {
        if (property) {
            return value.filter(x => !set.map(y => y[property]).includes(x[property]));
        }

        return value.filter(x => !set.includes(x));
    }
}
