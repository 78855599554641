import { Component, Input } from '@angular/core';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { NgIf } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tlk-officers',
    templateUrl: './component.html',
    styleUrls: ['./component.scss'],
    standalone: true,
    imports: [NgbTooltip, FaIconComponent, NgIf]
})
export class StatsOfficersComponent {
  public icon = faUser;

  isNaN = isNaN;

  private _value: string;
  private _total: string = null;

  public get value(): string {
    return this._value;
  }
  @Input()
  public set value(value: string) {
    if (this.isNaN(Number(value)))
      this._value = "?";
    else
      this._value = value;
  }

  @Input()
  public get total(): string {
    return this._total;
  }
  public set total(value: string) {
    if (this.isNaN(Number(value)))
      this._total = "?";
    else
      this._total = value;
  }
}
