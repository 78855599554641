export enum SoldierProperty {
  Infantry,
  Ranged,
  Cavalry
}

export function soldierPropertyRepresentation(soldierProperty: SoldierProperty): any {
  switch (soldierProperty) {
    case SoldierProperty.Cavalry:
      return {
        name: 'Cavalry',
        description: 'Attacks in the second round of battle, after artillery but before infantry.'
      };
    case SoldierProperty.Infantry:
      return {
        name: 'Infantry',
        description: 'Attacks in the melee rounds. Gains much experience in training battles.'
      };
    case SoldierProperty.Ranged:
      return {
        name: 'Ranged',
        description: 'Attacks in the first round of battle, before cavalry or infantry.'
      };
  }
}
