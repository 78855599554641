import { Component, Input } from '@angular/core';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tlk-health',
    templateUrl: './component.html',
    styleUrls: ['./component.scss'],
    standalone: true,
    imports: [NgbTooltip, FaIconComponent]
})
export class StatsHealthComponent {
  public icon = faHeart;

  @Input()
  value: string;

  constructor(
  ) { }

}
