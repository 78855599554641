<div class="dropdown-root">
    <div class="dropdown-item-content input-group">
        <div class="form-control" *ngIf="!isShowingItems" (click)="toggleItems()">
            <ng-template *ngIf="value"
                         [ngTemplateOutlet]="itemTemplate"
                         [ngTemplateOutletContext]="{ item: value, index: -1 }">
            </ng-template>
        </div>
        <input type="text" class="form-control" *ngIf="isShowingItems"
               #txtFilter
               placeholder="filter resultaten"
               [(ngModel)]="Filter">
        <span class="input-group-btn">
            <button class="btn btn-secondary" type="button" (click)="toggleItems()">
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </button>
        </span>
    </div>
    <div *ngIf="isShowingItems" class="dropdown-content">
        <ul *ngIf="!GroupBy">
            <li *ngFor="let item of items | filterBy: Filter"
                (click)="selectItem(item)"
                class="dropdown-item">
                <div class="dropdown-item-content">
                    <ng-template [ngTemplateOutlet]="itemTemplate"
                                 [ngTemplateOutletContext]="{ item: item }">
                    </ng-template>
                </div>
            </li>
        </ul>
        <ul *ngIf="GroupBy">
            <li *ngFor="let group of items | filterBy: Filter | groupBy: GroupBy | sort: 'key'"
                class="group-item">
                <div class="group-header">
                    {{group.key}}
                </div>
                <div class="group-items">
                    <ul>
                        <li *ngFor="let item of group.values"
                            (click)="selectItem(item)"
                            (mousedown)="selectItem(item)"
                            class="dropdown-item">
                            <div class="dropdown-item-content">
                                <ng-template [ngTemplateOutlet]="itemTemplate"
                                             [ngTemplateOutletContext]="{ item: item }">
                                </ng-template>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</div>
