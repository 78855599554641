import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'online',
    pure: true,
    standalone: true
})
export class OnlinePipe implements PipeTransform {
  transform(value: any[], onlineOnly: boolean = true): any {
    if (!onlineOnly)
      return value;

    return value.filter(x => x.status === 'online');
  }
}
