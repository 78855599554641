<ng-template #first><span aria-hidden="true">&laquo;&laquo;</span></ng-template>
<!--<ng-template #previous><span aria-hidden="true">&laquo;</span></ng-template>-->
<!--<ng-template #next><span aria-hidden="true">&raquo;</span></ng-template>-->
<ng-template #last><span aria-hidden="true">&raquo;&raquo;</span></ng-template>
<ng-template #ellipsis>...</ng-template>
<!--<ng-template #defaultNumber let-page let-currentPage="currentPage">
  {{ page }}
  <span *ngIf="page === currentPage" class="sr-only">(current)</span>
</ng-template>-->
<ul>
  <li *ngIf="boundaryLinks"
      [class.disabled]="previousDisabled()"
      (click)="selectPage(1); $event.preventDefault()" >
    <a aria-label="First" href
       [attr.tabindex]="previousDisabled() ? '-1' : null"
       [attr.aria-disabled]="previousDisabled() ? 'true' : null">
      <ng-template [ngTemplateOutlet]="first"
                   [ngTemplateOutletContext]="{disabled: previousDisabled(), currentPage: page}"></ng-template>
    </a>
  </li>
  <li *ngIf="directionLinks"
      [class.disabled]="previousDisabled()"
      (click)="selectPage(page-1); $event.preventDefault()">
    <a aria-label="Previous" href
        [attr.tabindex]="previousDisabled() ? '-1' : null"
       [attr.aria-disabled]="previousDisabled() ? 'true' : null">
      <fa-icon [icon]="faCaretLeft" size="'lg'"></fa-icon>
    </a>
  </li>
  <li *ngFor="let pageNumber of pages"
      [class.current]="pageNumber === page"
      (click)="selectPage(pageNumber); $event.preventDefault()"
      [class.disabled]="isEllipsis(pageNumber) || disabled"
      [attr.aria-current]="(pageNumber === page ? 'page' : null)">
    <a *ngIf="isEllipsis(pageNumber)" tabindex="-1" aria-disabled="true">
      <ng-template [ngTemplateOutlet]="ellipsis"
                   [ngTemplateOutletContext]="{disabled: true, currentPage: page}"></ng-template>
    </a>
    <a *ngIf="!isEllipsis(pageNumber)" href
       [attr.tabindex]="disabled ? '-1' : null" [attr.aria-disabled]="disabled ? 'true' : null">
      {{ pageNumber }}
      <span *ngIf="pageNumber === page" class="sr-only">(current)</span>
    </a>
  </li>
  <li *ngIf="directionLinks"
      [class.disabled]="nextDisabled()"
      (click)="selectPage(page+1); $event.preventDefault()">
    <a aria-label="Next" href
        [attr.tabindex]="nextDisabled() ? '-1' : null"
       [attr.aria-disabled]="nextDisabled() ? 'true' : null">
      <fa-icon [icon]="faCaretRight" size="'lg'"></fa-icon>
    </a>
  </li>
  <li *ngIf="boundaryLinks"
      [class.disabled]="nextDisabled()"
      (click)="selectPage(pageCount); $event.preventDefault()">
    <a aria-label="Last" href
        [attr.tabindex]="nextDisabled() ? '-1' : null"
       [attr.aria-disabled]="nextDisabled() ? 'true' : null">
      <ng-template [ngTemplateOutlet]="last"
                   [ngTemplateOutletContext]="{disabled: nextDisabled(), currentPage: page}"></ng-template>
    </a>
  </li>
</ul>
