import { Compiler, Injectable, Injector, NgModuleFactory, NgModuleRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { StorageKey } from '@app/enums/storage-key.enum';
import { Age } from '@app/models/age.model';

import { Url as NeptuneStyle } from '@app/modules/events/neptune/style';
import { Url as PandemicStyle } from '@app/modules/events/pandemic/style';
import { Url as HalloweenStyle } from '@app/modules/events/halloween/style';

@Injectable({
  providedIn: 'root'
})
export class AgeService {
  public Age: BehaviorSubject<Age> = new BehaviorSubject<Age>(null);

  private _event: string = null;

  public get Event(): string {
    return this._event;
  }

  public set Event(value: string) {
    if (this._event == value)
      return;

    this._event = value;

    if (this._event == "NeptunesWrath") {
      this.ApplyStyle(this._event, NeptuneStyle);
    }
    else if (this._event == "Pandemic") {
      this.ApplyStyle(this._event, PandemicStyle);
    }
    else if (this._event == "Halloween") {
      this.ApplyStyle(this._event, HalloweenStyle);
    }
  }

  private ApplyStyle(name: string, url: string) {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.id = 'css-' + name;
    style.rel = 'stylesheet';
    style.href = url;
    head.appendChild(style);
  }

    constructor(
      private http: HttpClient,
      private injector: Injector,
      private compiler: Compiler
    ) { }

    public load(): Promise<Age> {
      return this.GetAgeData();
  }

  public GetAgeData(): Promise<Age> {
    return new Promise<Age>((resolve, reject) => {
      this.http.post<Age>('/json.php', {
        page: 'general',
        request: 'getAgeData'
      }).subscribe(item => {
        this.Event = item.event;
        this.Age.next(item);

        // TODO: Improve thise code
        if (item.event == "NeptunesWrath") {
          import('@app/modules/events/neptune/neptune.module')
            .then(m => m.NeptuneModule)
            .then(moduleOrFactory => {
              if (moduleOrFactory instanceof NgModuleFactory) {
                return moduleOrFactory;
              } else {
                return this.compiler.compileModuleAsync(moduleOrFactory);
              }
            })
            .then(factory => {
              factory.create(this.injector).instance as unknown as NgModuleRef<any>;
            });
        }
        else if (item.event == "Pandemic") {
          import('@app/modules/events/pandemic/pandemic.module').then(m => m.PandemicModule)
            .then(moduleOrFactory => {
              if (moduleOrFactory instanceof NgModuleFactory) {
                return moduleOrFactory;
              } else {
                return this.compiler.compileModuleAsync(moduleOrFactory);
              }
            })
            .then(factory => {
              factory.create(this.injector).instance as unknown as NgModuleRef<any>;
            });
        }
        else if (item.event == "Halloween") {
          import('@app/modules/events/halloween/halloween.module').then(m => m.HalloweenModule)
            .then(moduleOrFactory => {
              if (moduleOrFactory instanceof NgModuleFactory) {
                return moduleOrFactory;
              } else {
                return this.compiler.compileModuleAsync(moduleOrFactory);
              }
            })
            .then(factory => {
              factory.create(this.injector).instance as unknown as NgModuleRef<any>;
            });
        }
        else if (item.event == "Navy") {
          import('@app/modules/events/navy/navy.module').then(m => m.NavyModule)
            .then(moduleOrFactory => {
              if (moduleOrFactory instanceof NgModuleFactory) {
                return moduleOrFactory;
              } else {
                return this.compiler.compileModuleAsync(moduleOrFactory);
              }
            })
            .then(factory => {
              factory.create(this.injector).instance as unknown as NgModuleRef<any>;
            });
        }

        resolve(item);
      }, () => reject());
    });
  }

    public isActive(): boolean {
      const data = this.Age.getValue();
      return data.ticksToStart <= 0 && data.ticksToEnd > 0;
  }
}
