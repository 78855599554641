import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ticksToDays',
    pure: true,
    standalone: true
})
export class TicksToDaysPipe implements PipeTransform {
    transform(value: number): any {
        return Math.floor(value / 48);
    }
}
