import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IRoad } from '@app/interfaces/road.interface';
import { Road } from '@app/models/road.model';
import { StorageKey } from '@app/enums/storage-key.enum';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IRoadService } from './iroad.service';
import { ServiceNotReadyError } from '@app/exceptions/service-not-ready.error';


@Injectable({
  providedIn: 'root'
})
export class RoadService extends IRoadService {
  private roads: any;

  constructor(
    protected http: HttpClient
  ) {
    super(http);
  }

  public load(data: IRoad[]) {
    Object.keys(data).forEach((key) => {
      let roads = data[key];
      let newRoads = roads.map(road => Object.assign(new Road(), road as Road));
      data[key] = newRoads;
    });

    this.roads = data;
  }

  public getCityRoads(id: number): IRoad[] {
    if (this.roads == null)
      throw new ServiceNotReadyError("Roads service not ready yet");

    return this.roads[id.toString()];
  }

  public getRoads(): IRoad[] {
    if (this.roads == null)
      throw new ServiceNotReadyError("Roads service not ready yet");

    let roads = [];

    Object.keys(this.roads).forEach((key) => {
      roads.concat(this.roads[key]);
    });

    return roads;
  }

  public getSeaRoutes(): IRoad[] {
    if (this.roads == null)
      throw new ServiceNotReadyError("Roads service not ready yet");

    let searoutes = [];
    let origins = [];

    Object.keys(this.roads).forEach((key) => {
      origins.push(Number(key));

      this.roads[key].forEach((road => {
        if (road.type != 'Sea' && road.type != 'Event')
          return;

        if (origins.indexOf(road.toId) !== -1)
          return;

        searoutes.push(road);
      }));
    });

    return searoutes;
  }

  public fetchDynamicData(): Observable<IRoad[]> {
    return this.http.post<any[]>('/json.php', {
      page: 'general',
      request: 'getRoadsDynamicData'
    }).pipe(map((data: any[]) => {
      if (data != null) {
        data.forEach((dynamicRoad) => {
          let road = Object.assign(new Road(), dynamicRoad as Road);

          // Roads are grouped by from-city id, so get the correct group
          let group = this.roads[road.fromId];

          // Check if the road exists
          let index = group.findIndex(x => x.toId === road.toId);

          if (index == -1) // Add to group
            group.push(road);
          else // Overwrite existing
            group[index] = road;
        });
      }

      return this.roads;
    }));
  }
}
