<div class="Message">
  <div style="display:flex; align-items: stretch; flex-wrap: wrap;">
    <div class="MessageInfo">
      <!--<div>-->
        <div style="display:flex; justify-content:space-between; align-items:center;">
          <div class="MessageTime">
            <t [T]="message.CreationDate"></t>
          </div>
          <input *ngIf="!hideReply && message.Sender != null && message.Sender.length > 0 && message.Sender !== 'System'"
                 type="button"
                 class="button"
                 value="Reply"
                 style="margin-left: 20px;"
                 (click)="Reply()">
        </div>
        <div class="MessageSender"
             *ngIf="message.Sender != null && message.Sender.length > 0 && message.Sender !== 'System'">
          <div>
            <tlk-user-rank style="margin: 1px;" [OriginalRank]="message.SenderRank"></tlk-user-rank> {{message.SenderRank}}
            <b><a routerLink="/user/profile/{{message.Sender}}">{{message.Sender}}</a></b>
          </div>
          <div>
            <tlk-country-flag style="margin: 1px; width: 27px;" [Nation]="{ Name: message.SenderNation, Map: age.map }"></tlk-country-flag> {{message.SenderNation}}
            <span *ngIf="message.SenderHc == 1"> (WC)</span>
            <span *ngIf="message.SenderHc == 2"> (HC)</span>
            <span *ngIf="message.SenderHc == 3"> (LD)</span>
          </div>
        </div>
        <!--<div>
        </div>-->
      <!--</div>-->
    </div>
    <div class="MessageContent">
      <span [innerHTML]="message.Text | replaceWithLocal"></span>
    </div>
  </div>
  <div class="MessageReceiverInfo" *ngIf="message.Sender != null && message.Sender.length > 0 && message.Sender !== 'System'">
    <div class="MessageDetails">
      Sent to
      <span *ngIf="message.SentCount > 5">{{message.SentCount}} players</span>
      <ul *ngIf="message.SentCount <= 5" class="comma-list">
        <li *ngFor="let Receiver of message.Receivers">{{Receiver.Name}}</li>
      </ul>
      <input type="button" class="mx-2 my-1" [value]="message.ShowReceiverDetails ? 'Hide Details' : 'Show Details'" (click)="ToggleReceiverDetails()" />
      <div *ngIf="ShowReceiverDetails" style="margin-left: 10px; margin-top: 10px;">
        <div *ngFor="let Receiver of message.ReceiverDetails">
          <tlk-player [NationId]="Receiver.NationId" [RankId]="Receiver.RankId" [Female]="Receiver.Female" [Name]="Receiver.Name"></tlk-player>
          <fa-icon class="ms-2" *ngIf="Receiver.Seen != null" [icon]="faCheck"></fa-icon>
        </div>
      </div>
    </div>
  </div>
</div>
