import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'TicksInjured',
    pure: true,
    standalone: true
})
export class TicksInjuredPipe implements PipeTransform {
  transform(value: number, healamount: number): any {
    if (healamount == null) {
      healamount = 30;
    }

    return Math.ceil(value / healamount);
  }
}
