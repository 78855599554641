import { Component, OnInit } from '@angular/core';
import { ICity } from '@app/interfaces/city.interface';
import { INation } from '@app/interfaces/nation.interface';
import { IRank } from '@app/interfaces/rank.interface';
import { Player } from '@app/models/player.model';
import { CheatsService } from '@app/services/cheats.service';
import { ICityService } from '@app/services/icity.service';
import { INationService } from '@app/services/ination.service';
import { PlayerService } from '@app/services/player.service';
import { IRankService } from '@app/services/irank.service';
import { Subscription } from 'rxjs';
import { UserService } from '@app/services/user.service';
import { User } from '@app/models/user.model';
import { AgeService } from '@app/services/age.service';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'tlk-cheats',
    templateUrl: './cheats.component.html',
    styleUrls: ['./cheats.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor]
})
export class CheatsComponent implements OnInit {
  player: Player;
  user: User;
  ranks: IRank[] = [];
  cities: ICity[] = [];
  nations: INation[] = [];
  playerSubscription: Subscription;
  userSubscription: Subscription;
  ageSubscription: Subscription;

  constructor(
    public cheat: CheatsService,
    private rankService: IRankService,
    private cityService: ICityService,
    private nationService: INationService,
    private playerService: PlayerService,
    private userService: UserService,
    private ageService: AgeService
  ) { }

  ngOnInit(): void {
    this.playerSubscription = this.playerService.getData().subscribe(data => {
      this.player = data;
    });

    this.userSubscription = this.userService.getData().subscribe(data => {
      this.user = data;
    });
    this.ranks = this.rankService.getRanks();
    this.cities = this.cityService.getCities();
    this.ageSubscription = this.ageService.Age.subscribe(data => {
      this.nations = this.nationService.getNations().filter(x => data.map === x.Map).concat({ Name: 'No Country', Id: 0 } as INation);
    });    
  }

  ngOnDestroy() {
    this.playerSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.ageSubscription.unsubscribe();
  }

}
