import { HostListener, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { EncryptionService } from "./encryption.service";

@Injectable({
  providedIn: 'root'
})
export class ActivityTrackerService {

  public routeSubscription: Subscription;
  public WindowActive = true;
  public ClickPositions = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private EncryptionService: EncryptionService
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(
      (x: NavigationEnd) => {
        this.OnPageChanging(x.url); // $location.path()
      });
  }

  @HostListener('window:click', ['$event'])
  public onClick(event: any): void {
    this.OnMouseClick(event.pageX, event.pageY);
  }

  @HostListener('window:focus', ['$event'])
  public onFocus(event: any): void {
    this.OnWindowStateChanged(true);
  }

  @HostListener('window:blur', ['$event'])
  public onBlur(event: any): void {
    this.OnWindowStateChanged(false);
  }

  public OnMouseClick(x, y) {
    let value = "" + x + "," + y;
    let lastValue = this.ClickPositions[this.ClickPositions.length - 1];

    if (value != lastValue) {
      this.ClickPositions.push(value);

      if (this.ClickPositions.length > 50) {
        this.ClickPositions.shift(); // removes the first element from an array
      }
    }
  }

  public OnPageChanging(page) {
    let validationData = JSON.stringify(
      {
        Page: page,
        Positions: this.ClickPositions,
        Status: this.WindowActive
      });

    let encryptedData = this.EncryptionService.Encrypt("TLKSECRET", validationData);

    this.http.post<any[]>('/json.php', {
      page: 'validate',
      request: 'validate',
      params: [encryptedData]
    }).subscribe(result => {
    });
  }

  public OnWindowStateChanged(activeState) {
    this.WindowActive = activeState;
  }
}
