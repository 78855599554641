<!--<object *ngIf="SvgUrl"
        [data]="SvgUrl"
        type="image/svg+xml"
        class="rank symbol">-->
  <img *ngIf="PngUrl"
       [src]="PngUrl"
       class="rank"
       [alt]="RankName"
       [title]="RankName">
<!--</object>-->
