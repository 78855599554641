import { Component, Input} from '@angular/core';

@Component({
    selector: 'tlk-progressbar',
    templateUrl: './progressbar.component.html',
    styleUrls: ['./progressbar.component.scss'],
    standalone: true
})
export class ProgressbarComponent  {
  @Input() public max: number = 100;
  @Input() public value: number = 0;
}
