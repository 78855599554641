import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { finalize } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class CheatsService {
    constructor(private http: HttpClient, private authService: AuthService) {}
    
    public run(cheat: string, data: any): Promise<any> {
        return this.http.post('/experimentalAPI.php', {
            resource: cheat,
            ...data
        }, { responseType: 'text' }).pipe(finalize(() => {
            this.authService.loadUserAndPlayerDataIfLoggedIn();
        })).toPromise();
    }
}